import api from "../api";
import orderEndpoint from "./config";

const order = {
  async getWaitingOrder(query = "") {
    return await api.get(orderEndpoint.getWaitingOrder + query);
  },
  async getConfirmedOrder(query = "") {
    return await api.get(orderEndpoint.getConfirmedOrder + query);
  },
  async confirmOrder(data) {
    return await api.post(orderEndpoint.confirmOrder, { data });
  },
  async generateOrderBarCode(id) {
    return await api.get(`/order/${id}/generate-barcode`);
  },
  async readyOrder(id) {
    return await api.get(`/order/${id}/ready-order`);
  },
  async getReceivedOrder(query = "") {
    return await api.get(orderEndpoint.getReceivedOrder + query);
  },
  async getReadyOrder(query = "") {
    return await api.get(orderEndpoint.getReadyOrder + query);
  },
  async readyForReturnOrder(id) {
    return await api.get(`/order/${id}/ready-return-order`);
  },
  async assignToTransporter(data) {
    return await api.post(orderEndpoint.assignToTransporter, data);
  },
  async getExpressPickOrder(query = "") {
    return await api.get(orderEndpoint.getExpressPickOrder + query);
  },
  async assignToRider(ids, selectedRider) {
    return await api.post(orderEndpoint.assignToRider, { ids, selectedRider });
  },
  async getExpressWaitingOrder(query = "") {
    return await api.get(orderEndpoint.getExpressWaitingOrder + query);
  },
  async getExpressConfirmedOrder(query = "") {
    return await api.get(orderEndpoint.getExpressConfirmedOrder + query);
  },
  async acceptExpressOrder() {
    return await api.get(orderEndpoint.acceptExpressOrder);
  },
  async getExpressReceivedOrder(query = "") {
    return await api.get(orderEndpoint.getExpressReceivedOrder + query);
  },
  async getExpressReadyOrder(query = "") {
    return await api.get(orderEndpoint.getExpressReadyOrder + query);
  },
  async assignToRiderFromWarehouse(ids, selectedRider) {
    return await api.post(orderEndpoint.assignToRiderFromWarehouse, {
      ids,
      selectedRider,
    });
  },
  async getAllOrder() {
    return await api.get(orderEndpoint.getAllOrder);
  },

  async addOfflineOrder(data) {
    return await api.post(orderEndpoint.addOfflineOrder, data);
  },

  async downloadInvoice(id) {
    return await api.get(`/order/${id}/download-invoice`);
  },

  async quickAssign(id, rider) {
    return await api.get(`/order/${id}/${rider}/quick-assign`);
  },

  async updateOrder(data, id) {
    return await api.post(orderEndpoint.updateOrder + "/" + id, data);
  },
};

export default order;
