import { useState, useEffect } from "react";
import { getRandomNumber } from "../../utils/helper";
import ToastContext from "./toastContext";
import Item from "./item";
import "../../constants/styles.scss";
import "./styles.scss";

const ToastContainer = ({ children }) => {
  const [arr, setArr] = useState([]);

  useEffect(() => {
    if (arr.length >= 1) {
      let timer = setTimeout(() => {
        let newArr = [...arr];
        newArr.shift();
        setArr(newArr);
      }, 2000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [arr]);

  const toastMessage = (type, message) => {
    // let newArr = [...arr];
    // newArr.push({
    //   type,
    //   message,
    //   id: getRandomNumber(),
    // });
    // setArr(newArr);
    setArr([
      {
        type,
        message,
        id: getRandomNumber(),
      },
    ]);
  };

  const onClickCross = (item) => {
    let newArr = [...arr];
    const index = newArr.indexOf(item);
    newArr.splice(index, 1);
    setArr(newArr);
  };

  return (
    <ToastContext.Provider value={{ toastMessage }}>
      <div className="toast-main-container">
        {arr.map((item) => (
          <Item
            key={item.id}
            type={item.type}
            message={item.message}
            id={item.id}
            onClickCross={() => onClickCross(item)}
          />
        ))}
      </div>
      {children}
    </ToastContext.Provider>
  );
};

export default ToastContainer;
