import React, { useEffect, useState } from "react";
import Box from "./box";
import AllOrderBox from "./orders";
import "./styles.scss";
import Loader from "../../components/loader";
import common from "../../apis/common";
import AccessDenied from "../../assets/images/access-denied.jpg";

const Home = (props) => {
  const [spinner, setSpinner] = useState(false);
  const [data, setData] = useState({
    order: {},
    warehouse: 0,
    customer: 0,
    rider: 0,
    data: [],
  });

  const getData = async () => {
    try {
      setSpinner(true);
      const response = await common.getDashboardData();
      setData(response?.data);
      setSpinner(false);
    } catch (err) {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (!props?.is_access) {
    return (
      <>
        <div className="no-access-section">
          <div style={{ height: "25vh" }}></div>
          <img src={AccessDenied} alt="" style={{ width: "20%" }} />
          <br />
          <label className="gr">
            You currently does not have access to this page
          </label>
          <br />
          <label className="gr">Please try again latter</label>
        </div>
      </>
    );
  }

  return (
    <>
      {spinner && (
        <div>
          <Loader loading={spinner} />
        </div>
      )}
      <div className="row">
        <div className="right-head-section">
          <h6 className="page-heading">Dashboard</h6>
        </div>
        <Box data={data} />
        <AllOrderBox data={data} />
      </div>
    </>
  );
};

export default Home;
