import { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import loginPageClipart from "../../assets/images/loginPageClipart.jpg";

import Text from "../../components/text";
import Input from "../../components/input";
import Button from "../../components/button";
import ToastContext from "../../components/toast/toastContext";

import { forgotpasswordLink } from "../../store/reducer/auth";
import { setLoader } from "../../store/reducer/ui";
import { verifyEmail, isLoggedIn } from "../../utils/helper";

import "./styles.scss";
import "../../constants/styles.scss";

const Forgot = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { toastMessage } = useContext(ToastContext);
  const [emailValue, setEmailValue] = useState("");
  const [emailErrorText, setEmailErrorText] = useState("");

  useEffect(() => {
    isLoggedIn() && navigate("/");
  });

  const onChangeInput = (event, type) => {
    const value = event.target.value;
    switch (type) {
      case "email":
        setEmailValue(value);
        verifyEmail(value)
          ? setEmailErrorText("")
          : setEmailErrorText("Enter valid email address.");
        break;
      default:
        break;
    }
  };

  const onClickLogin = async () => {
    dispatch(setLoader(true));
    const data = {
      email: emailValue,
    };
    const { error, payload } = await dispatch(forgotpasswordLink(data));
    if (error) {
      toastMessage("error", "Incorrect Email address");
    } else if (payload.status === 200 && payload.data.status) {
      toastMessage(
        "success",
        "Successfully Password reset link sent on your email id"
      );
      navigate("/login");
    }
    dispatch(setLoader(false));
  };

  const loginPage = () => {
    navigate("/login");
  };

  return (
    <div className="container-fluid">
      <div className="row">
      <div className="col-5 display-flex-column-align-center-justify-center">
          <Text
            className="fw-b fs-22 mb-2 text-center-align"
            value={
              <>
                A champion is afraid of losing.<br></br>Everyone else is afraid of winning.
              </>
            }
          />
          <Text
            className="fs-16 mb-4 text-center-align"
            value={
              <>
                Success is peace of mind which is a direct result of self-satisfaction in knowing you did your best to become the best you are capable of becoming.
              </>
            }
          />
          <img src={loginPageClipart} alt="profile-img" width="70%" />
        </div>
        <div
          className="col-7 login-page-right-side-style 
        display-flex-column-align-center-justify-center"
        >
          <Text
            className="fs-24 fw-b mb-5 text-center-align"
            value="Forgot Password"
          />
          <Input
            className="forgot-page-fields"
            label="Email Address"
            placeholder="Enter Your Email Address"
            value={emailValue}
            onChange={(event) => {
              onChangeInput(event, "email");
            }}
            errorText={emailErrorText}
          />
          <Button
            className="mb-4"
            text="Send Link"
            onClick={onClickLogin}
            disabled={verifyEmail(emailValue) ? false : true}
          />
          <Button type="link" onClick={() => loginPage()} text="Login?" />
        </div>
      </div>
    </div>
  );
};

export default Forgot;
