import api from "../api";
import authEndpoint from "./config";

const auth = {
  async postLoginAddedUsers(payload) {
    return await api.post(authEndpoint.loginAddedUsers, payload);
  },
  async postForgotPasswordLink(payload) {
    return await api.post(authEndpoint.forgotPasswordUrl, payload);
  },
  async logoutUser() {
    await api.get(authEndpoint.logoutUser);
  },
};

export default auth;
