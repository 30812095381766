import "./styles.scss";
import { useState, useEffect, useContext } from "react";
import { Row, Col, Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";

import all_order from "../../../assets/images/dashboard/all_order.svg";
import pending from "../../../assets/images/dashboard/receiver_order.svg";
import deliver_order from "../../../assets/images/dashboard/deliver_order.svg";
import completed from "../../../assets/images/dashboard/complete.svg";
import transit from "../../../assets/images/dashboard/transit_order.svg";
import express from "../../../assets/images/dashboard/express_order.svg";
import cleaning from "../../../assets/images/dashboard/cleaning_order.svg";
import delivery_partner from "../../../assets/images/dashboard/delivery_partner.svg";
import transporter from "../../../assets/images/dashboard/transporter.svg";
import iron_partner from "../../../assets/images/dashboard/iron_partner.svg";

const statusColors = {
  all_order: {
    color: "#01b49e",
    icon: all_order,
    label: "All Order",
  },
  receiver: {
    color: "#2C1867",
    icon: pending,
    label: "Receiver Order",
  },
  ready: {
    color: "#A7A7A7",
    icon: transit,
    label: "Ready Order",
  },
  deliver: {
    color: "#38EDCC",
    icon: deliver_order,
    label: "Deliver Order",
  },
  pending: {
    color: "#F58500",
    icon: completed,
    label: "Pending Order",
  },
  express: {
    color: "#D14DFF",
    icon: express,
    label: "Express Order",
  },
  cleaning: {
    color: "#0F79DB",
    icon: cleaning,
    label: "Cleaning Partners",
  },
  rider: {
    color: "#C90000",
    icon: delivery_partner,
    label: "Delivery Partners",
  },
  transport: {
    color: "#0F79DB",
    icon: transporter,
    label: "Transporter",
  },
  iron: {
    color: "#A7A7A7",
    icon: iron_partner,
    label: "Iron Partners",
  },
};

const StatusCard = (props) => {
  const { data, handlePopup } = props;
  return (
    <div
      className="dashboard-status-card"
      style={{ color: statusColors[data?._id].color, cursor: "pointer" }}
      onClick={() => {
        handlePopup(data?._id);
      }}
    >
      <div className="dashboard-status-card-top">
        <h2>{data?.key}</h2>
        <img src={statusColors[data?._id].icon} alt={data?._id} />
      </div>
      <div className="dashboard-status-card-bottom">
        <div>
          <p>
            {Intl.NumberFormat("en-IN").format(parseInt(data?.amount || 0))}
          </p>
        </div>
      </div>
    </div>
  );
};

const Box = (props) => {
  const [open, setOpen] = useState(false);
  const [userOpen, setUserOpen] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);

  const data = props?.data
    ? Object.keys(statusColors).map((key) => {
        let resData = null;
        if (props.data?.order[key]) {
          resData = {
            _id: key,
            key: statusColors[key].label,
            amount: props.data?.order[key],
          };
        } else if (
          key === "cleaning" ||
          key === "rider" ||
          key === "iron" ||
          key === "transport"
        ) {
          resData = {
            _id: key,
            key: statusColors[key].label,
            amount: props.data[key],
          };
        } else {
          resData = {
            _id: key,
            key: statusColors[key].label,
            amount: 0,
          };
        }
        return resData;
      })
    : [];

  const handlePopup = (type) => {
    if (type == "all_order") {
      setSelectedOrders(props?.data?.data || []);
    } else if (type == "receiver") {
      let arr = props?.data?.data?.filter(
        (x) =>
          ["WAITING_RECEIVED_BY_WAREHOUSE", "RECEIVED_BY_WAREHOUSE"].includes(
            x?.status
          ) && x?.type != "SUPER_FAST"
      );
      setSelectedOrders(arr);
    } else if (type == "ready") {
      let arr = props?.data?.data?.filter(
        (x) =>
          ["READY_FOR_RETURN"].includes(x?.status) && x?.type != "SUPER_FAST"
      );
      setSelectedOrders(arr);
    } else if (type == "deliver") {
      let arr = props?.data?.data?.filter(
        (x) =>
          ["READY_FOR_SERVICE", "READY_FOR_RETURN"].includes(x?.status) &&
          x?.type != "SUPER_FAST"
      );
      setSelectedOrders(arr);
    } else if (type == "pending") {
      let arr = props?.data?.data?.filter(
        (x) =>
          [
            "INITIATE",
            "RECEIVED_BY_RIDER_FROM_CUSTOMER",
            "WAITING_RECEIVED_BY_TRANSPORTER_FROM_RIDER",
            "RECEIVED_BY_TRANSPORTER_FROM_RIDER",
          ].includes(x?.status) && x?.type != "SUPER_FAST"
      );
      setSelectedOrders(arr);
    } else if (type == "express") {
      let arr = props?.data?.data?.filter(
        (x) =>
          [
            "WAITING_RECEIVED_BY_WAREHOUSE",
            "RECEIVED_BY_WAREHOUSE",
            "READY_FOR_SERVICE",
            "READY_FOR_RETURN",
          ].includes(x?.status) && x?.type == "SUPER_FAST"
      );
      setSelectedOrders(arr);
    } else if (type == "cleaning") {
      setSelectedUser(props?.data?.cleaningData || []);
      setUserOpen(true);
      return;
    } else if (type == "iron") {
      setSelectedUser(props?.data?.ironData || []);
      setUserOpen(true);
      return;
    } else if (type == "rider") {
      setSelectedUser(props?.data?.riderData || []);
      setUserOpen(true);
      return;
    } else if (type == "transport") {
      setSelectedUser(props?.data?.transportData || []);
      setUserOpen(true);
      return;
    } else {
      return;
    }
    setOpen(true);
  };

  const toggle = () => {
    setOpen(false);
    setUserOpen(false);
  };

  return (
    <div className="dashboard-status">
      <StatusCard
        data={{
          _id: "all_order",
          key: "All Order",
          amount: props.data?.order["all_order"] || 0,
        }}
        filter={props.filter}
        handlePopup={handlePopup}
      />
      {data?.map(
        (status, index) =>
          statusColors[status._id] &&
          status.key !== "All Order" && (
            <StatusCard
              data={status}
              filter={props.filter}
              key={index}
              handlePopup={handlePopup}
            />
          )
      )}

      <Modal
        size="lg"
        className="ledger-modal"
        isOpen={open}
        toggle={toggle}
        style={{ minWidth: "80%" }}
      >
        <ModalHeader className="order-detail-modal-header">
          Order List
        </ModalHeader>
        <ModalBody className="order-detail-outer">
          <TableContainer className="table_container all-order-table">
            <Table stickyHeader aria-label="sticky table">
              <TableHead className="data_tbl_head_row">
                <TableRow style={{ background: "none" }}>
                  <TableCell>Order ID</TableCell>
                  <TableCell>Tag</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Customer</TableCell>
                  <TableCell>Rider</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="data_tbl_body_row">
                {selectedOrders.map((row, index) => (
                  <TableRow key={index} style={{ background: "none" }}>
                    <TableCell>{row.order_id}</TableCell>
                    <TableCell>{row.tag_no || "NA"}</TableCell>
                    <TableCell>{row.type}</TableCell>
                    <TableCell>{row.customer?.name || "NA"}</TableCell>
                    <TableCell>{row.rider?.name || "NA"}</TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell>
                      {moment(row.createdAt).format("DD MMM YYYY hh:mm A")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ModalBody>
      </Modal>

      <Modal
        size="lg"
        className="ledger-modal"
        isOpen={userOpen}
        toggle={toggle}
        style={{ minWidth: "80%" }}
      >
        <ModalHeader className="order-detail-modal-header">
          User List
        </ModalHeader>
        <ModalBody className="order-detail-outer">
          <TableContainer className="table_container all-order-table">
            <Table stickyHeader aria-label="sticky table">
              <TableHead className="data_tbl_head_row">
                <TableRow style={{ background: "none" }}>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Mobile No</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="data_tbl_body_row">
                {selectedUser.map((row, index) => (
                  <TableRow key={index} style={{ background: "none" }}>
                    <TableCell>{row._id}</TableCell>
                    <TableCell>{row.name || "NA"}</TableCell>
                    <TableCell>{row.mobile_no}</TableCell>
                    <TableCell>{row?.role || "Customer"}</TableCell>
                    <TableCell>
                      {moment(row.createdAt).format("DD MMM YYYY hh:mm A")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Box;
