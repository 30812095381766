import { createAsyncThunk } from "@reduxjs/toolkit";

import auth from "../../../apis/auth";
import authEndpoint from "../../../apis/auth/config";

export const loginAddedUsers = createAsyncThunk(
  `${authEndpoint.loginAddedUsers}Post`,
  async (payload) => {
    const { status, data } = await auth.postLoginAddedUsers(payload);
    return { status, data };
  }
);

export const forgotpasswordLink = createAsyncThunk(
  `${authEndpoint.loginAddedUsers}Post`,
  async (payload) => {
    const { status, data } = await auth.postForgotPasswordLink(payload);
    return { status, data };
  }
);

// export const logoutUser = createAsyncThunk(
//   `${authEndpoint.logoutUser}Get`,
//   async () => {
//     const { status, data } = await auth.logoutUser();
//     return { status, data };
//   }
// );
