import Colors from "../../constants/colors";

const Exclamation = ({ color = Colors.black, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.001"
      height="18.001"
      viewBox="0 0 18.001 18.001"
    
      {...props}
    >
      <g
        id="Group_42473"
        data-name="Group 42473"
        transform="translate(-22376.999 -2394)"
      >
        <path
          id="Path_92930"
          data-name="Path 92930"
          d="M169.721,90.719a9,9,0,1,0,9,9A9.006,9.006,0,0,0,169.721,90.719Zm0,16.723a7.722,7.722,0,1,1,7.722-7.722A7.728,7.728,0,0,1,169.721,107.442Z"
          transform="translate(22216.279 2303.281)"
          fill={color === Colors.black ? Colors.black : color}
        />
        <path
          id="Path_92931"
          data-name="Path 92931"
          d="M335.731,337.4a.88.88,0,0,1-.631-.252.926.926,0,0,1-.221-.6.861.861,0,0,1,.252-.6.877.877,0,0,1,1.2,0,.861.861,0,0,1,.252.6.807.807,0,0,1-.252.6A.746.746,0,0,1,335.731,337.4Z"
          transform="translate(22050.459 2069.351)"
          fill={color === Colors.black ? Colors.black : color}
        />
        <path
          id="Path_92932"
          data-name="Path 92932"
          d="M335.731,199.47a.787.787,0,0,1-.852-.709v-3.729a.866.866,0,0,1,1.7,0v3.73A.787.787,0,0,1,335.731,199.47Z"
          transform="translate(22050.459 2204.901)"
          fill={color === Colors.black ? Colors.black : color}
        />
      </g>
    </svg>
  );
};

export default Exclamation;
