import { useState, useEffect, useContext } from "react";
import "./styles.scss";
import Loader from "../../components/loader";
import { Row, Col, Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import moment from "moment/moment";
import ToastContext from "../../components/toast/toastContext";
import { Autocomplete, TextField } from "@mui/material";
import common from "../../apis/common";
import Chip from "@mui/material/Chip";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import order from "../../apis/order";
import Confirmation from "../../components/confirmation";
import GoogleAddress from "../../components/googleAddress";
import api from "../../apis/api";
import { X } from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate, useParams } from "react-router-dom";
import AccessDenied from "../../assets/images/access-denied.jpg";

const OfflineCustomer = (props) => {
  const { id } = useParams("id");
  const navigate = useNavigate();
  const { toastMessage } = useContext(ToastContext);

  const [defaultDetail, setDefault] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [customersMob, setCustomersMob] = useState([]);
  const [customerForm, setCustomerForm] = useState({
    name: "",
    mobile_no: "",
    email: "",
    customer_type: "B2C",
  });
  const [orderForm, setOrderForm] = useState({
    type: "NORMAL",
    pick_date: "",
    pick_time_slot: "",
    drop_date: "",
    drop_time_slot: "",
    tag_no: "",
    category: "Walk_In",
  });
  const [addressForm, setAddressForm] = useState({
    type: "HOME",
    longitude: "",
    latitude: "",
    address: "",
    pin: "",
    house_no: "",
    street: "",
    landmark: "",
  });

  const [servicesList, setServicesList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([
    {
      _id: "",
      name: "",
      quantity: "",
      amount: "",
    },
  ]);

  const [pickDate, setPickDate] = useState([]);
  const [dropDate, setDropDate] = useState([]);
  const [pickSlot, setPickSlot] = useState([]);
  const [open, setOpen] = useState(false);

  const [showModal, setShowModel] = useState({
    show: false, // initial values set to false and null
    id: null,
    title: "Order Confirm",
    message: "",
  });

  const [center, setCenter] = useState({
    lat: 37.7749,
    lng: -122.4194,
  });

  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const [valueMob, setValueMob] = useState(null);
  const [inputValueMob, setInputValueMob] = useState("");

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
  };

  useEffect(() => {
    getUserLocation();
  }, []);

  const getCustomers = async (from = "") => {
    try {
      const response = await common.getCustomers(
        from == "Name" && inputValue
          ? inputValue
          : from == "Mobile" && inputValueMob
          ? inputValueMob
          : ""
      );
      setCustomers(
        response?.data?.data.map((x) => {
          return {
            id: x?._id,
            name: x?.name,
            mobile_no: x?.mobile_no,
            email: x?.email,
          };
        })
      );
      setCustomersMob(
        response?.data?.data.map((x) => {
          return {
            id: x?._id,
            name: x?.mobile_no,
            customer_name: x?.name,
            email: x?.email,
          };
        })
      );
    } catch (err) {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getCustomers("Name");
  }, [inputValue]);

  useEffect(() => {
    getCustomers("Mobile");
  }, [inputValueMob]);

  const getOrderDetail = async (dft, serviceArr) => {
    try {
      const response = await common.getOrderDetail(id);
      if (!response?.data?.data) {
        navigate("/order");
      }
      setCustomerForm({
        name: response?.data?.data?.customer?.name,
        mobile_no: response?.data?.data?.customer?.mobile_no,
        email: response?.data?.data?.customer?.email,
        customer_type: response?.data?.data?.customer?.customer_type,
      });

      setInputValue(response?.data?.data?.customer?.name || "");
      setInputValueMob(response?.data?.data?.customer?.mobile_no || "");

      setOrderForm({
        type: response?.data?.data?.type,
        pick_date: moment(response?.data?.data?.pick_date).format("YYYY-MM-DD"),
        pick_time_slot: response?.data?.data?.pick_time_slot,
        drop_date: moment(response?.data?.data?.drop_date).format("YYYY-MM-DD"),
        drop_time_slot: response?.data?.data?.drop_time_slot,
        tag_no: response?.data?.data?.tag_no,
        category: response?.data?.data?.category,
      });

      dft.currentDate = new Date(response?.data?.data?.pick_date);
      setDefault(dft);

      setAddressForm({
        _id: response?.data?.data?.address_id,
        address: response?.data?.data?.address,
      });
      let arr = [];
      serviceArr.map((x) => {
        const ind = response?.data?.data?.services.findIndex(
          (y) => y.service_id.toString() == x?._id.toString()
        );
        if (ind > -1) {
          x.quantity = response?.data?.data?.services[ind]?.quantity;
          x.weight = response?.data?.data?.services[ind]?.weight;
        }
        x.is_weight_based = x?.weight ? true : x?.quantity ? false : true;
        arr.push(x);
      });
      setServicesList(arr);
      const dryCleanInd = response?.data?.data?.services.findIndex(
        (x) =>
          x?.type == "DRY_CLEAN" &&
          x?.selected_items &&
          x?.selected_items?.length
      );
      let arr1 = [];
      if (dryCleanInd > -1) {
        response?.data?.data?.services[dryCleanInd]?.selected_items.map((x) => {
          const itemInd = dft?.items.findIndex(
            (y) => y._id.toString() == x?._id?.toString()
          );
          if (itemInd > -1) {
            arr1.push({
              ...x,
              amount: dft?.items[itemInd].amount,
            });
          }
        });
      }
      arr1.push({
        _id: "",
        name: "",
        quantity: "",
        amount: "",
      });
      setSelectedItems(arr1);
      setSpinner(false);
    } catch (err) {
      navigate("/order");
    }
  };

  const getData = async () => {
    try {
      setSpinner(true);
      const response = await common.getOfflineCustomerData();
      setPickSlot(response?.data?.slot);
      let arr = response?.data?.services.map((x) => {
        return {
          ...x,
          quantity: "",
          weight: "",
          fixed_amount: x?.amount,
          fixed_piece_amount: x?.piece_amount,
          is_weight_based: true,
        };
      });

      if (id != -1) {
        getOrderDetail(response?.data, arr);
      } else {
        setDefault(response?.data);
        setServicesList(arr);
        setSpinner(false);
      }
    } catch (err) {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const setDefaultOrderValues = () => {
    let arr = [];
    for (let i = 0; i < 8; i++) {
      let currentDate = moment(defaultDetail?.currentDate).add(i, "days");
      arr.push({
        dayInNumber: moment(currentDate).format("DD"),
        day: moment(currentDate).format("ddd"),
        date: moment(currentDate).format("YYYY-MM-DD"),
      });
    }
    setPickDate(arr);
    let arr1 = [];
    for (let i = 0; i < 8; i++) {
      const currentDate = moment(defaultDetail?.currentDate).add(i + 3, "days");
      arr1.push({
        dayInNumber: moment(currentDate).format("DD"),
        day: moment(currentDate).format("ddd"),
        date: moment(currentDate).format("YYYY-MM-DD"),
      });
    }
    setDropDate(arr1);

    const currentTime = moment(defaultDetail?.currentDate).format("HH");
    let arrSlot = [];
    defaultDetail?.slot.map((x) => {
      let startTime = 0;
      if (x?.start.includes("AM")) {
        startTime = Number(x.start.replace("AM", ""));
      } else if (x?.start.includes("PM")) {
        const tm = Number(x.start.replace("PM", ""));
        startTime = tm >= 12 ? tm : 12 + tm;
      }

      if (Number(currentTime) <= startTime) {
        arrSlot.push(x);
      }
    });
    setPickSlot(arrSlot);
    if (id == -1) {
      setOrderForm({
        ...orderForm,
        type: "NORMAL",
        pick_date: arr[0].date,
        drop_date: arr1[0].date,
        pick_time_slot: arrSlot.length ? arrSlot[0].title : "",
        drop_time_slot: "",
        tag_no: "",
      });
    }
  };

  useEffect(() => {
    setDefaultOrderValues();
  }, [defaultDetail]);

  const onChangeInput = (val, key) => {
    setCustomerForm({
      ...customerForm,
      [key]: val,
    });
    if (key == "customer_type") {
      setServicesList(
        servicesList
          ?.filter((x) => x.code != "DRY_CLEAN")
          .map((x) => {
            return {
              ...x,
              amount: x?.fixed_amount,
              piece_amount: x?.fixed_piece_amount,
            };
          })
      );
      setSelectedItems(
        selectedItems.map((x) => {
          let ind = defaultDetail?.items.findIndex((y) => y._id == x?._id);
          return {
            ...x,
            amount: ind > -1 ? defaultDetail?.items[ind].amount : "",
          };
        })
      );
    }
  };

  const onChangeAddress = (val, key) => {
    if (key == "type") {
      addressForm.other_type = "";
    }
    setAddressForm({
      ...addressForm,
      [key]: val,
    });
  };

  const onChangeOrder = (val, key) => {
    if (key == "drop_date" && !orderForm.pick_date) {
      return toastMessage("error", "Please select Pick date before drop date");
    }
    if (key == "drop_time_slot" && !orderForm.pick_date) {
      return toastMessage("error", "Please select Pick date before drop date");
    }

    if (key == "pick_date") {
      let arr = [];
      for (let i = 0; i < 8; i++) {
        let currentDate = null;
        if (orderForm?.type == "NORMAL") {
          currentDate = moment(val).add(i + 3, "days");
        } else if (orderForm?.type == "FAST") {
          currentDate = moment(val).add(i + 2, "days");
        } else {
          currentDate = moment(val).add(i + 1, "days");
        }
        arr.push({
          dayInNumber: moment(currentDate).format("DD"),
          day: moment(currentDate).format("ddd"),
          date: moment(currentDate).format("YYYY-MM-DD"),
        });
      }
      orderForm.drop_date = arr[0].date;
      orderForm.drop_time_slot = "";
      setDropDate(arr);

      const today = moment(defaultDetail?.currentDate).format("YYYY-MM-DD");
      if (val == today) {
        const currentTime = moment(defaultDetail?.currentDate).format("HH");
        let arrSlot = [];
        defaultDetail?.slot.map((x) => {
          let startTime = 0;
          if (x?.start.includes("AM")) {
            startTime = Number(x.start.replace("AM", ""));
          } else if (x?.start.includes("PM")) {
            const tm = Number(x.start.replace("PM", ""));
            startTime = tm >= 12 ? tm : 12 + tm;
          }
          if (Number(currentTime) <= startTime) {
            arrSlot.push(x);
          }
        });
        setPickSlot(arrSlot);
        orderForm.pick_time_slot = arrSlot.length ? arrSlot[0].title : "";
      } else {
        setPickSlot(defaultDetail?.slot || []);
        orderForm.pick_time_slot = defaultDetail?.slot.length
          ? defaultDetail?.slot[0].title
          : "";
      }
    } else if (key == "type" && orderForm?.pick_date) {
      let arr = [];
      for (let i = 0; i < 8; i++) {
        let currentDate = null;
        if (val == "NORMAL") {
          currentDate = moment(orderForm?.pick_date).add(i + 3, "days");
        } else if (val == "FAST") {
          currentDate = moment(orderForm?.pick_date).add(i + 2, "days");
        } else {
          currentDate = moment(orderForm?.pick_date).add(i + 1, "days");
        }
        arr.push({
          dayInNumber: moment(currentDate).format("DD"),
          day: moment(currentDate).format("ddd"),
          date: moment(currentDate).format("YYYY-MM-DD"),
        });
      }
      orderForm.drop_date = arr[0].date;
      orderForm.drop_time_slot = "";
      setDropDate(arr);
    }
    setOrderForm({
      ...orderForm,
      [key]: val,
    });
  };

  const onchangeItems = (val, key, index) => {
    if (key == "_id") {
      let ind = selectedItems.findIndex((x) => x._id == val);
      if (ind > -1) {
        return toastMessage("error", "Item already exist ");
      }
    }
    selectedItems[index][key] = val;
    if (key == "_id") {
      let ind = defaultDetail?.items.findIndex((x) => x._id == val);
      if (ind > -1) {
        selectedItems[index]["name"] = defaultDetail?.items[ind].name;
        selectedItems[index]["amount"] = defaultDetail?.items[ind].amount;
      }
    }

    if (key == "_id" && selectedItems[selectedItems.length - 1]._id) {
      selectedItems.push({
        _id: "",
        name: "",
        quantity: "",
        amount: "",
      });
    }
    setSelectedItems([...selectedItems]);
  };

  const onChangeServices = (val, key, index) => {
    servicesList[index][key] = val;
    if (key == "is_weight_based") {
      servicesList[index]["weight"] = "";
    }
    setServicesList([...servicesList]);
  };

  const deleteItem = (index) => {
    if (!selectedItems[index]._id) {
      return;
    }
    if (selectedItems.length > 1) {
      selectedItems.splice(index, 1);
    } else {
      selectedItems[index]._id = "";
      selectedItems[index].quantity = "";
    }
    setSelectedItems([...selectedItems]);
  };

  const calculateAmount = () => {
    let amt = 0;
    servicesList.filter(x => x.code != "DRY_CLEAN").map((x) => {
      if (x.quantity && x.weight && x.quantity > 0 && x.weight > 0) {
        const discountAmt = (Number(x?.amount) * Number(x?.discount)) / 100;
        amt += (Number(x.amount) - Number(discountAmt)) * Number(x.weight);
      } else if (!x?.is_weight_based && x?.quantity && x?.quantity > 0) {
        const discountAmt =
          (Number(x?.piece_amount) * Number(x?.discount)) / 100;
        amt +=
          (Number(x.piece_amount) - Number(discountAmt)) * Number(x.quantity);
      }
    });
    const details = servicesList.find((x) => x.code == "DRY_CLEAN");
    selectedItems.map((x) => {
      if (x.quantity) {
        const discountAmt =
          (Number(x?.amount) * Number(details?.discount)) / 100;
          console.log(amt, (Number(x.amount) - Number(discountAmt)) * Number(x.quantity))
        amt += (Number(x.amount) - Number(discountAmt)) * Number(x.quantity);
      }
    });
    if (orderForm?.type == "FAST") {
      return amt + (amt * 50) / 100;
    } else if (orderForm?.type == "SUPER_FAST") {
      return amt * 2;
    }
    return amt;
  };

  const resetForm = () => {
    setDefaultOrderValues();
    setValue(null);
    setInputValue("");
    setValueMob(null);
    setInputValueMob("");
    setCustomerForm({
      name: "",
      mobile_no: "",
      email: "",
      customer_type: "",
      customer_type: "B2C",
    });
    setAddressForm({
      type: "HOME",
      longitude: "",
      latitude: "",
      address: "",
      pin: "",
      house_no: "",
      street: "",
      landmark: "",
    });
    let arr = defaultDetail?.services.map((x) => {
      return {
        ...x,
        quantity: "",
        weight: "",
        fixed_amount: x?.fixed_amount,
        fixed_piece_amount: x?.fixed_piece_amount,
        is_weight_based: true,
      };
    });
    setServicesList(arr);
    setSelectedItems([
      {
        _id: "",
        name: "",
        quantity: "",
        amount: "",
      },
    ]);
  };

  const submitForm = () => {
    if (!inputValue || !inputValueMob) {
      return toastMessage("error", "Customer detail is requieds");
    }
    if (!orderForm?.pick_time_slot) {
      return toastMessage("error", "Please select pick time slot");
    }
    if (!orderForm?.drop_time_slot) {
      return toastMessage("error", "Please select drop time slot");
    }
    if (
      !addressForm?._id &&
      (!addressForm?.latitude || !addressForm?.longitude)
    ) {
      return toastMessage("error", "Please select location from google map");
    }
    if (!orderForm?.tag_no) {
      return toastMessage("error", "Please attach Bag tag no");
    }

    const isAmount = calculateAmount();
    if (isAmount == 0) {
      return toastMessage("error", "Please select laundry service");
    }

    setShowModel({
      show: true,
      title: "Order Confirm!",
      message:
        "Make sure all details are correct, you cannot rollback this order ?",
    });
  };

  const hideConfirmationModal = () => {
    setShowModel({
      show: false,
      id: null,
      title: "",
      message: "",
    });
  };

  const submitConfirmed = async () => {
    setShowModel({
      show: false,
      id: null,
      title: "",
      message: "",
    });
    try {
      setSpinner(true);
      customerForm.name = inputValue;
      customerForm.mobile_no = inputValueMob;
      const obj = {
        customer_detail: customerForm,
        order_detail: orderForm,
        address_detail: addressForm,
        services: servicesList.map((x) => {
          if (x.code == "DRY_CLEAN") {
            x.selected_items = selectedItems.filter((y) => y._id && y.quantity);
            let quantity = 0;
            selectedItems
              .filter((y) => y._id && y.quantity)
              .map((y) => {
                quantity += y.quantity ? Number(y.quantity) : 0;
              });
            x.quantity = quantity;
          }
          return x;
        }),
      };

      if (id == -1) {
        const response = await order.addOfflineOrder(obj);
        if (response?.data?.status) {
          toastMessage("success", "Order successfully placed");
          resetForm();
        } else if (
          response?.response?.data &&
          !response?.response?.data?.status
        ) {
          toastMessage(
            "error",
            response?.response?.data?.message || "Something went wrong"
          );
        } else {
          toastMessage("error", "Something went wrong");
        }
      } else {
        const response = await order.updateOrder(obj, id);
        if (response?.data?.status) {
          toastMessage("success", "Order Updated Successfully");
          resetForm();
          navigate("/order");
        } else if (
          response?.response?.data &&
          !response?.response?.data?.status
        ) {
          toastMessage(
            "error",
            response?.response?.data?.message || "Something went wrong"
          );
        } else {
          toastMessage("error", "Something went wrong");
        }
      }
      setSpinner(false);
    } catch (err) {
      console.log(err);
      setSpinner(false);
    }
  };

  const toggle = () => {
    setOpen(!open);
  };

  const onSubmitAddress = async () => {
    api
      .post(`/google/full-address`, {
        lat: center?.lat,
        lng: center?.lng,
      })
      .then((response) => {
        setAddressForm({
          ...addressForm,
          address: response?.data?.location,
          latitude: center?.lat,
          longitude: center?.lng,
        });
        setOpen(false);
      });
  };

  const handleChange = async (event, newValue) => {
    setValue(newValue);
    setCustomerForm({
      ...customerForm,
      customer_id: newValue?.id || "",
      email: newValue?.email || "",
    });
    if (!newValue) {
      return;
    }
    setInputValueMob(newValue?.mobile_no);
    const response = await common.getAddress(newValue?.id);
    if (response?.data?.data) {
      setAddressForm({
        ...addressForm,
        ...response?.data?.data,
      });
    }
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    setCustomerForm({
      ...customerForm,
      customer_id: "",
    });
  };

  const handleChangeMob = async (event, newValue) => {
    setValueMob(newValue);
    setCustomerForm({
      ...customerForm,
      customer_id: newValue?.id || "",
      email: newValue?.email || "",
    });
    if (!newValue) {
      return;
    }
    setInputValue(newValue?.customer_name);
    const response = await common.getAddress(newValue?.id);
    if (response?.data?.data) {
      setAddressForm({
        ...addressForm,
        ...response?.data?.data,
      });
    }
  };

  const handleInputChangeMob = (event, newInputValue) => {
    setInputValueMob(newInputValue);
    setCustomerForm({
      ...customerForm,
      customer_id: "",
    });
  };

  const getDryCleanLabel = () => {
    const detail = servicesList.find((x) => x.code == "DRY_CLEAN");
    return <b>Dry Clean ({detail?.discount || 0}% Off)</b>;
  };

  if (!props?.is_access) {
    return (
      <>
        <div className="no-access-section">
          <div style={{ height: "25vh" }}></div>
          <img
            src={AccessDenied}
            alt=""
            style={{ width: "20%" }}
          />
          <br />
          <label className="gr">
            You currently does not have access to this page
          </label>
          <br />
          <label className="gr">Please try again latter</label>
        </div>
      </>
    );
  }

  return (
    <>
      {spinner && (
        <div>
          <Loader loading={spinner} />
        </div>
      )}

      <div className="row profile-section">
        <div className="right-head-section">
          <h6 className="page-heading">Offline Customer</h6>
          <div className="transporter-list"></div>
        </div>
        <div className="row customer-outer-section mt-2">
          {/* <Col md={12} className="mt-2"> */}
          <h6 className="cust-main-heading">Customer Detail</h6>
          <Row className="p-0">
            <Col md={4}>
              {/* <TextField
                type="text"
                id="outlined-basic"
                variant="outlined"
                fullWidth
                size="small"
                value={customerForm?.name}
                label="Name"
                onChange={(e) => {
                  onChangeInput(e.target.value, "name");
                }}
                autoComplete="off"
              /> */}
              <Autocomplete
                value={value}
                onChange={handleChange}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                options={customers}
                getOptionLabel={(option) => option.name}
                disabled={id != -1}
                renderInput={(params) => (
                  <TextField {...params} label="Customer" variant="outlined" />
                )}
                size="small"
                freeSolo // This allows for entering values not in the list
              />
            </Col>
            <Col md={4}>
              {/* <TextField
                type="number"
                id="outlined-basic"
                variant="outlined"
                fullWidth
                size="small"
                value={customerForm?.mobile_no}
                label="Mobile No"
                onChange={(e) => {
                  onChangeInput(e.target.value, "mobile_no");
                }}
                autoComplete="off"
              /> */}
              <Autocomplete
                value={valueMob}
                onChange={handleChangeMob}
                inputValue={inputValueMob}
                onInputChange={handleInputChangeMob}
                options={customersMob}
                getOptionLabel={(option) => option.name}
                disabled={id != -1}
                renderInput={(params) => (
                  <TextField {...params} label="Mobile No" variant="outlined" />
                )}
                size="small"
                freeSolo // This allows for entering values not in the list
              />
            </Col>
            <Col md={4}>
              <TextField
                type="email"
                id="outlined-basic"
                variant="outlined"
                fullWidth
                size="small"
                value={customerForm?.email}
                label="Email Id"
                disabled={id != -1}
                onChange={(e) => {
                  onChangeInput(e.target.value, "email");
                }}
                autoComplete="off"
              />
            </Col>
          </Row>
          {/* </Col> */}

          <Col md={7} className="mt-2">
            <h6 className="cust-main-heading mt-3">Customer Type</h6>
            <div className="address_type_section">
              <span className="chip_outer">
                {customerForm?.customer_type == "B2C" ? (
                  <Chip
                    label="B2C"
                    color="success"
                    onClick={() => {
                      onChangeInput("B2C", "customer_type");
                    }}
                  />
                ) : (
                  <Chip
                    label="B2C"
                    color="success"
                    variant="outlined"
                    onClick={() => {
                      onChangeInput("B2C", "customer_type");
                    }}
                  />
                )}
              </span>
              <span className="chip_outer">
                {customerForm?.customer_type == "B2B" ? (
                  <Chip
                    label="B2B"
                    color="success"
                    onClick={() => {
                      onChangeInput("B2B", "customer_type");
                    }}
                  />
                ) : (
                  <Chip
                    label="B2B"
                    color="success"
                    variant="outlined"
                    onClick={() => {
                      onChangeInput("B2B", "customer_type");
                    }}
                  />
                )}
              </span>
            </div>
            <h6 className="cust-main-heading mt-3">Delivery Type</h6>
            <div className="address_type_section">
              <span className="chip_outer">
                {orderForm?.type == "NORMAL" ? (
                  <Chip
                    label="Normal"
                    color="success"
                    onClick={() => {
                      onChangeOrder("NORMAL", "type");
                    }}
                  />
                ) : (
                  <Chip
                    label="Normal"
                    color="success"
                    variant="outlined"
                    onClick={() => {
                      onChangeOrder("NORMAL", "type");
                    }}
                  />
                )}
              </span>
              <span className="chip_outer">
                {orderForm?.type == "FAST" ? (
                  <Chip
                    label="Fast"
                    color="success"
                    onClick={() => {
                      onChangeOrder("FAST", "type");
                    }}
                  />
                ) : (
                  <Chip
                    label="Fast"
                    color="success"
                    variant="outlined"
                    onClick={() => {
                      onChangeOrder("FAST", "type");
                    }}
                  />
                )}
              </span>
              <span className="chip_outer">
                {orderForm?.type == "SUPER_FAST" ? (
                  <Chip
                    label="Super Fast"
                    color="success"
                    onClick={() => {
                      onChangeOrder("SUPER_FAST", "type");
                    }}
                  />
                ) : (
                  <Chip
                    label="Super Fast"
                    color="success"
                    variant="outlined"
                    onClick={() => {
                      onChangeOrder("SUPER_FAST", "type");
                    }}
                  />
                )}
              </span>
            </div>

            <h6 className="cust-main-heading mt-3">Order Type</h6>
            <div className="address_type_section">
              <span className="chip_outer">
                {orderForm?.category == "Walk_In" ? (
                  <Chip
                    label="Walk In"
                    color="success"
                    onClick={() => {
                      onChangeOrder("Walk_In", "category");
                    }}
                  />
                ) : (
                  <Chip
                    label="Walk In"
                    color="success"
                    variant="outlined"
                    onClick={() => {
                      onChangeOrder("Walk_In", "category");
                    }}
                  />
                )}
              </span>
              <span className="chip_outer">
                {orderForm?.category == "On_Call" ? (
                  <Chip
                    label="On Call"
                    color="success"
                    onClick={() => {
                      onChangeOrder("On_Call", "category");
                    }}
                  />
                ) : (
                  <Chip
                    label="On Call"
                    color="success"
                    variant="outlined"
                    onClick={() => {
                      onChangeOrder("On_Call", "category");
                    }}
                  />
                )}
              </span>
              <span className="chip_outer">
                {orderForm?.category == "On_Whatsapp" ? (
                  <Chip
                    label="On Whatsapp"
                    color="success"
                    onClick={() => {
                      onChangeOrder("On_Whatsapp", "category");
                    }}
                  />
                ) : (
                  <Chip
                    label="On Whatsapp"
                    color="success"
                    variant="outlined"
                    onClick={() => {
                      onChangeOrder("On_Whatsapp", "category");
                    }}
                  />
                )}
              </span>
            </div>

            {/* pickup */}
            <h6 className="cust-main-heading mt-5">Pickup Slot</h6>
            <p className="cust-inner-heading">
              <i class="fa fa-calendar" aria-hidden="true"></i>
              <span>Select Date</span>
            </p>
            <div className="select_date_slot">
              {pickDate.map((dt, index) => {
                return (
                  <div
                    className={`date_slot ${
                      orderForm?.pick_date == dt?.date ? "active_date_slot" : ""
                    }`}
                    key={index}
                    onClick={() => {
                      onChangeOrder(dt?.date, "pick_date");
                    }}
                  >
                    <p className="date">{dt?.dayInNumber}</p>
                    <p className="day">{dt?.day}</p>
                  </div>
                );
              })}
            </div>
            <p className="cust-inner-heading">
              <i class="fa fa-clock-o" aria-hidden="true"></i>
              <span>Select Slot</span>
            </p>
            <div className="p-md-4 select_time_slot">
              {pickSlot.map((tm) => {
                return (
                  <div
                    className={`time_slot ${
                      orderForm?.pick_time_slot == tm?.title
                        ? "active_time_slot"
                        : ""
                    }`}
                    onClick={() => {
                      onChangeOrder(tm?.title, "pick_time_slot");
                    }}
                  >
                    <span>{tm?.title}</span>
                  </div>
                );
              })}
            </div>

            {/* drop */}
            <h6 className="cust-main-heading mt-5">Drop Slot</h6>
            <p className="cust-inner-heading">
              <i class="fa fa-calendar" aria-hidden="true"></i>
              <span>Select Date</span>
            </p>
            <div className="select_date_slot">
              {dropDate.map((dt, index) => {
                return (
                  <div
                    className={`date_slot ${
                      orderForm?.drop_date == dt?.date ? "active_date_slot" : ""
                    }`}
                    key={index}
                    onClick={() => {
                      onChangeOrder(dt?.date, "drop_date");
                    }}
                  >
                    <p className="date">{dt?.dayInNumber}</p>
                    <p className="day">{dt?.day}</p>
                  </div>
                );
              })}
            </div>
            <p className="cust-inner-heading">
              <i class="fa fa-clock-o" aria-hidden="true"></i>
              <span>Select Slot</span>
            </p>
            <div className="p-md-4 select_time_slot">
              {defaultDetail?.slot &&
                defaultDetail?.slot.map((tm) => {
                  return (
                    <div
                      className={`time_slot ${
                        orderForm?.drop_time_slot == tm?.title
                          ? "active_time_slot"
                          : ""
                      }`}
                      onClick={() => {
                        onChangeOrder(tm?.title, "drop_time_slot");
                      }}
                    >
                      <span>{tm?.title}</span>
                    </div>
                  );
                })}
            </div>

            <div className="row mt-4">
              <TextField
                type="text"
                id="outlined-basic"
                variant="outlined"
                fullWidth
                size="small"
                value={orderForm?.tag_no}
                label="Bag Tag"
                disabled={id != -1}
                onChange={(e) => {
                  onChangeOrder(e.target.value, "tag_no");
                }}
                autoComplete="off"
              />
            </div>
          </Col>
          <Col md={5} className="mt-2 right_section">
            <h6 className="cust-main-heading mt-3">Address</h6>
            <p
              className="cust-inner-heading"
              onClick={toggle}
              style={{ cursor: "pointer" }}
            >
              <i class="fa fa-map-marker" aria-hidden="true"></i>
              <span>Detect customer location from google map</span>
            </p>
            <div className="address_type_section">
              <label>Type</label>
              <span className="chip_outer">
                {addressForm?.type == "HOME" ? (
                  <Chip
                    label="Home"
                    color="success"
                    onClick={() => {
                      onChangeAddress("HOME", "type");
                    }}
                  />
                ) : (
                  <Chip
                    label="Home"
                    color="success"
                    variant="outlined"
                    onClick={() => {
                      onChangeAddress("HOME", "type");
                    }}
                  />
                )}
              </span>
              <span className="chip_outer">
                {addressForm?.type == "OFFICE" ? (
                  <Chip
                    label="Office"
                    color="success"
                    onClick={() => {
                      onChangeAddress("OFFICE", "type");
                    }}
                  />
                ) : (
                  <Chip
                    label="Office"
                    color="success"
                    variant="outlined"
                    onClick={() => {
                      onChangeAddress("OFFICE", "type");
                    }}
                  />
                )}
              </span>
              <span className="chip_outer">
                {addressForm?.type == "OTHER" ? (
                  <Chip
                    label="Other"
                    color="success"
                    onClick={() => {
                      onChangeAddress("OTHER", "type");
                    }}
                  />
                ) : (
                  <Chip
                    label="Other"
                    color="success"
                    variant="outlined"
                    onClick={() => {
                      onChangeAddress("OTHER", "type");
                    }}
                  />
                )}
              </span>
            </div>
            <div className="row mt-4">
              <TextField
                type="text"
                id="outlined-basic"
                variant="outlined"
                fullWidth
                size="small"
                value={addressForm?.save_as}
                disabled={addressForm?.type != "OTHER"}
                label="Save as"
                onChange={(e) => {
                  onChangeAddress(e.target.value, "save_as");
                }}
                autoComplete="off"
              />
            </div>
            <div className="row mt-4">
              <TextField
                type="text"
                id="outlined-basic"
                variant="outlined"
                fullWidth
                size="small"
                value={addressForm?.address}
                label="Addrdess"
                onChange={(e) => {
                  onChangeAddress(e.target.value, "address");
                }}
                autoComplete="off"
              />
            </div>
            <div className="row mt-4">
              <TextField
                type="text"
                id="outlined-basic"
                variant="outlined"
                fullWidth
                size="small"
                value={addressForm?.house_no}
                label="House/Flat/Street"
                onChange={(e) => {
                  onChangeAddress(e.target.value, "house_no");
                }}
                autoComplete="off"
              />
            </div>
            <div className="row mt-4">
              <TextField
                type="text"
                id="outlined-basic"
                variant="outlined"
                fullWidth
                size="small"
                value={addressForm?.landmark}
                label="Landmark"
                onChange={(e) => {
                  onChangeAddress(e.target.value, "landmark");
                }}
                autoComplete="off"
              />
            </div>
            <h6 className="cust-main-heading mt-5">Services</h6>
            <div className="services-section">
              {servicesList
                ?.filter((x) => x.code != "DRY_CLEAN")
                .map((service, index) => {
                  return (
                    <div className="row my-2">
                      {/* {service?.is_weight_based} */}
                      <Col md={1} style={{ padding: 0 }}>
                        <Checkbox
                          checked={service?.is_weight_based || false}
                          onChange={(e) => {
                            onChangeServices(
                              e.target.checked,
                              "is_weight_based",
                              index
                            );
                          }}
                        />
                      </Col>
                      <Col md={4} style={{ margin: "auto", padding: 0 }}>
                        <b>
                          {service?.name}
                          <br />
                          {service?.discount || 0}% Off
                        </b>
                      </Col>
                      <Col md={3} style={{ padding: "5px" }}>
                        <TextField
                          type="number"
                          id="outlined-basic"
                          variant="outlined"
                          fullWidth
                          size="small"
                          value={
                            service?.is_weight_based
                              ? service?.amount
                              : service?.piece_amount
                          }
                          label={
                            service?.is_weight_based
                              ? "Amount(Per Kg)"
                              : "Amount(Per Piece)"
                          }
                          disabled={customerForm?.customer_type == "B2C"}
                          onWheel={(e) => e.target.blur()}
                          onChange={(e) => {
                            onChangeServices(e.target.value, "amount", index);
                          }}
                          autoComplete="off"
                        />
                      </Col>
                      <Col md={2} style={{ padding: "5px" }}>
                        <TextField
                          type="number"
                          id="outlined-basic"
                          variant="outlined"
                          fullWidth
                          size="small"
                          value={service?.quantity}
                          label="Qty"
                          onWheel={(e) => e.target.blur()}
                          onChange={(e) => {
                            onChangeServices(e.target.value, "quantity", index);
                          }}
                          autoComplete="off"
                        />
                      </Col>
                      <Col md={2} style={{ padding: "5px" }}>
                        <TextField
                          type="number"
                          id="outlined-basic"
                          variant="outlined"
                          fullWidth
                          size="small"
                          value={service?.weight}
                          label="Weight"
                          onWheel={(e) => e.target.blur()}
                          onChange={(e) => {
                            onChangeServices(e.target.value, "weight", index);
                          }}
                          disabled={!service?.is_weight_based}
                          autoComplete="off"
                        />
                      </Col>
                    </div>
                  );
                })}
              {orderForm?.type != "SUPER_FAST" && (
                <>
                  <div className="row my-2">
                    <Col md={6} style={{ margin: "auto" }}>
                      {getDryCleanLabel()}
                    </Col>
                    <Col md={3}></Col>
                    <Col md={3}></Col>
                  </div>
                  {selectedItems.map((item, index) => {
                    return (
                      <div className="row my-2">
                        <Col md={4}>
                          <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-filled-label">
                              Items
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-filled-label"
                              id="demo-simple-select-filled"
                              value={item?._id}
                              label="Items"
                              onChange={(e) => {
                                onchangeItems(e.target.value, "_id", index);
                              }}
                            >
                              {defaultDetail?.items &&
                                defaultDetail?.items.map((x) => {
                                  return (
                                    <MenuItem value={x._id}>{x.name}</MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </Col>
                        <Col md={4}>
                          <TextField
                            type="number"
                            id="outlined-basic"
                            variant="outlined"
                            fullWidth
                            size="small"
                            value={item?.amount}
                            label="Amount"
                            disabled={customerForm?.customer_type == "B2C"}
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) => {
                              onchangeItems(e.target.value, "amount", index);
                            }}
                            autoComplete="off"
                          />
                        </Col>
                        <Col md={3}>
                          <TextField
                            type="number"
                            id="outlined-basic"
                            variant="outlined"
                            fullWidth
                            size="small"
                            value={item?.quantity}
                            label="Quantity"
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) => {
                              onchangeItems(e.target.value, "quantity", index);
                            }}
                            autoComplete="off"
                          />
                        </Col>
                        <Col
                          md={1}
                          className="delete_item"
                          onClick={() => {
                            deleteItem(index);
                          }}
                        >
                          <span>-</span>
                        </Col>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
            <b className="amt_label">
              Amount: INR <span>{calculateAmount().toFixed(2)}</span>
              <br />
              Tax Amount(18%): INR{" "}
              <span>
                {calculateAmount()
                  ? ((Number(calculateAmount()) * 18) / 100).toFixed(2)
                  : 0.0}
              </span>
              <br />
              Total Amount: INR{" "}
              <span>
                {(
                  Number(calculateAmount()) +
                  (calculateAmount()
                    ? (Number(calculateAmount()) * 18) / 100
                    : 0)
                ).toFixed(2)}
              </span>
            </b>
          </Col>

          <div className="btn_section">
            <Button
              className="cancel-btn"
              variant="success"
              onClick={resetForm}
            >
              Cancel Order
            </Button>
            <Button
              className="submit-btn"
              variant="success"
              onClick={submitForm}
            >
              {id == -1 ? "Confirm Order" : "Confirm Update Order"}
            </Button>
          </div>
        </div>
      </div>

      <Confirmation
        data={showModal}
        confirmModal={submitConfirmed}
        hideModal={hideConfirmationModal}
      />

      <Modal size="lg" className="ledger-modal" isOpen={open} toggle={toggle}>
        <ModalHeader>Select location</ModalHeader>
        <ModalBody className="order-detail-outer">
          <GoogleAddress
            onSubmitAddress={onSubmitAddress}
            center={center}
            setCenter={setCenter}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default OfflineCustomer;
