export const orderEndpoint = {
  getWaitingOrder: "/order/get-waiting-order",
  getConfirmedOrder: "/order/get-confirmed-order",
  confirmOrder: "/order/confirm-order",
  getReceivedOrder: "/order/get-received-order",
  getReadyOrder: "/order/get-ready-order",
  assignToTransporter: "/order/assign-to-transporter",
  getExpressPickOrder: "/order/express-pick-order",
  assignToRider: "/order/assign-to-express-rider",
  getExpressWaitingOrder: "/order/express-waiting-order",
  getExpressConfirmedOrder: "/order/express-confirmed-order",
  acceptExpressOrder: "/order/accept-express-order",
  getExpressReceivedOrder: "/order/express-received-order",
  getExpressReadyOrder: "/order/express-ready-order",
  assignToRiderFromWarehouse: "/order/assign-to-express-rider-from-warehouse",
  getAllOrder: "/order/getAll",
  addOfflineOrder: "/order/offline-customer",
  updateOrder: "/order/update-order",
};

export default orderEndpoint;
