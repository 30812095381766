import { useState, useEffect } from "react";
import "./styles.scss";
import qrImg from "../../assets/images/qr.png";
import userApi from "../../apis/user";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setInitialState } from "../../store/reducer/auth";
import { setLoader } from "../../store/reducer/ui";
import auth from "../../apis/auth";
import Loader from "../../components/loader";
import { Modal, ModalBody } from "reactstrap";
import domtoimage from "dom-to-image";

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [qr, setQR] = useState("");
  const [user, setUser] = useState({});
  const [spinner, setSpinner] = useState(false);
  const [open, setOpen] = useState(false);

  const getCurrentUser = async () => {
    try {
      setSpinner(true);
      const response = await userApi.getOneUser();
      setUser(response?.data?.data);
      setSpinner(false);
    } catch (err) {
      setSpinner(false);
    }
  };

  const getQRCode = async () => {
    try {
      const response = await userApi.getQRCode();
      setQR(response?.data?.data);
    } catch (err) {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getCurrentUser();
    getQRCode();
  }, []);

  const onClickLogout = async () => {
    dispatch(setLoader(true));
    await auth
      .logoutUser()
      .then((res) => {
        dispatch(setInitialState());
        navigate("/login");
      })
      .catch((err) => {
        dispatch(setInitialState());
        navigate("/login");
      });
    dispatch(setLoader(false));
  };

  const downloadImage = () => {
    if (!qr) {
      return;
    }
    var node = document.getElementById("my-node");

    domtoimage
      .toPng(node)
      .then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = `${user?.name}-QRCODE.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  };

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <>
      {spinner && (
        <div>
          <Loader loading={spinner} />
        </div>
      )}
      <div className="row profile-section">
        <div className="right-head-section">
          <h6 className="page-heading">Account Summary</h6>
          <span
            onClick={() => {
              onClickLogout();
            }}
            className="logout-btn"
          >
            Logout
          </span>
        </div>
        <div className="col-md-3 profile-left">
          <div className="static-qr-section">
            <label>Your QR Code</label> <br />
            <img
              src={qr ? qr : qrImg}
              alt="QR"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                toggle();
              }}
            />{" "}
            <br />
            <label
              style={{ color: "#1058FF", cursor: "pointer" }}
              onClick={downloadImage}
            >
              Download
            </label>
          </div>
        </div>
        <div className="col-md-9 profile-right">
          <label className="main-heading">PERSONAL INFORMATION</label>
          <div className="row" style={{ margin: "0-12px" }}>
            <div className="col-md-4">
              <label className="heading">Name</label>
              <p className="heading-val">{user?.name}</p>
            </div>
            <div className="col-md-4">
              <label className="heading">Email Id</label>
              <p className="heading-val">{user.email_id}</p>
            </div>
            <div className="col-md-4">
              <label className="heading">Mobile No</label>
              <p className="heading-val">+{user?.mobile_no}</p>
            </div>
          </div>
          <hr style={{ margin: "25px 0" }} />
          <label className="main-heading">BANK INFORMATION</label>
          <div className="row" style={{ margin: "0-12px" }}>
            <div className="col-md-4">
              <label className="heading">Bank Name</label>
              <p className="heading-val">{user?.bank_detail?.bank_name}</p>
            </div>
            <div className="col-md-4">
              <label className="heading">Account No</label>
              <p className="heading-val">
                {user?.bank_detail?.account_number.substring(0, 4)}********
              </p>
            </div>
            <div className="col-md-4">
              <label className="heading">Account Holder Name</label>
              <p className="heading-val">{user?.bank_detail?.holder_name}</p>
            </div>
          </div>
          <hr style={{ margin: "25px 0" }} />
          <label className="main-heading">KYC INFORMATION</label>
          <div className="row" style={{ margin: "0-12px" }}>
            <div className="col-md-4">
              <label className="heading">Aadhar No</label>
              <p className="heading-val">
                {user?.documents?.aadhar_number.substring(0, 4)} **** ****
              </p>
            </div>
            <div className="col-md-4">
              <label className="heading">License No</label>
              <p className="heading-val">
                {user?.documents?.license_number.substring(0, 4)} ********
              </p>
            </div>
            <div className="col-md-4">
              <label className="heading">PAN No</label>
              <p className="heading-val">
                {user?.documents?.pan_number.substring(0, 4)} ******
              </p>
            </div>
          </div>
        </div>

        <div
          style={{
            opacity: 0,
            position: "absolute",
            width: "100%",
            top: 0,
            zIndex: -1,
          }}
        >
          <div className="table-qr-section" id="my-node">
            <h6 style={{ color: "rgb(5 68 215)", fontWeight: "bold" }}>
              Your QR Code
            </h6>
            <img alt="QR Code" className="qr-img" src={qr} />
            <h6 style={{ fontWeight: "bold", margin: 0 }}>Scan Here</h6>
            <p style={{ fontSize: "10px" }}>
              Scan the QR code with your mobile phone
            </p>
            <div className="footer">
              <span>Scan&nbsp;&#8226;&nbsp;Order&nbsp;</span>
            </div>
          </div>
        </div>
      </div>

      <Modal size="sm" className="ledger-modal" isOpen={open} toggle={toggle}>
        <ModalBody style={{ textAlign: "center" }}>
          <label style={{ fontWeight: "700" }}>Your QR Code</label> <br />
          <img src={qr} alt="QR" width="100%" />
          <br />
          <label
            style={{ color: "#1058FF", cursor: "pointer", fontWeight: "700" }}
            onClick={downloadImage}
          >
            Download
          </label>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Profile;
