import { useState, useEffect, useContext } from "react";
import "./styles.scss";
import ToastContext from "../../components/toast/toastContext";
import Loader from "../../components/loader";
import Confirmation from "../../components/confirmation";
import {
  Col,
  CardBody,
  CardFooter,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  Table,
  Button,
  CardHeader,
} from "reactstrap";
import moment from "moment/moment";
import user from "../../apis/user";
import order from "../../apis/order";
import Checkbox from "@mui/material/Checkbox";
import Badge from "@mui/material/Badge";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import NoDataImg from "../../assets/images/no-data.jpg";
import Order from "../order";
import AccessDenied from "../../assets/images/access-denied.jpg";

const Rider = (props) => {
  const { toastMessage } = useContext(ToastContext);
  const [spinner, setSpinner] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedRider, setSelectedRider] = useState(null);
  const [rider, setRider] = useState([]);
  const [transporter, setTransporter] = useState([]);
  const [selectedTransporter, setSelectedTransporter] = useState("");
  const [readyOrder, setReadyOrder] = useState([]);
  const [showModal, setShowModel] = useState({
    show: false, // initial values set to false and null
    id: null,
    title: "Order Confirm",
    message: "",
  });

  const getRiderData = async () => {
    try {
      setSpinner(true);
      const response1 = await user.getAllRider();
      const response = await order.getReadyOrder();
      setReadyOrder(response?.data?.data);
      let arr = [];
      const end_date = moment().endOf("day");
      response1?.data?.data.map((item) => {
        const orders = response?.data?.data.filter(
          (x) =>
            x.delivery_id.toString() === item?._id.toString() &&
            new Date(x.drop_date).getTime() <= new Date(end_date).getTime()
        );
        arr.push({
          ...item,
          orders,
          checked: false,
        });
        return true;
      });
      setRider(arr);
      setSpinner(false);
    } catch (err) {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getRiderData();
  }, []);

  const getTransporterData = async () => {
    try {
      const response = await user.getAllTransporter();
      setTransporter(response?.data?.data);
    } catch (err) {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getTransporterData();
  }, []);

  const reloadData = () => {
    getRiderData();
  };

  const toggle = () => {
    setOpen(!open);
  };

  const showOrderDetail = (item) => {
    setSelectedRider(item);
    setOpen(true);
  };

  const hideConfirmationModal = () => {
    setShowModel({
      show: false,
      id: null,
      title: "",
      message: "",
    });
  };

  const submitConfirmed = async () => {
    setShowModel({
      show: false,
      id: null,
      title: "",
      message: "",
    });
    try {
      setSpinner(true);
      let selectedRider = [];
      rider
        .filter((x) => x.checked)
        .map((x) => {
          selectedRider.push(x?._id);
          return true;
        });
      const obj = {
        transporter_id: selectedTransporter,
        selectedRider,
      };
      const response = await order.assignToTransporter(obj);
      reloadData();
      const resultByte = response?.data?.pdf?.data;
      var bytes = new Uint8Array(resultByte);
      var blob = new Blob([bytes], { type: "application/pdf" });

      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      const ind = transporter.findIndex((x) => x._id === selectedTransporter);
      link.download = `Report-Transporter-${
        ind > -1 ? transporter[ind].name : "NA"
      }.pdf`;
      link.click();
      setSpinner(false);
      toastMessage("success", "Order successfully assign for return!");
      setSelectedTransporter("");
      setOpen(false);
    } catch (err) {
      console.log(err);
      setSpinner(false);
    }
  };

  const onConfirmOrder = async () => {
    if (rider.filter((x) => x.checked) === 0) {
      return toastMessage("error", "Please select riders from list");
    }
    if (!selectedTransporter) {
      return toastMessage("error", "Please select transporter");
    }
    if (!readyOrder?.length) {
      return toastMessage("error", "Order not found");
    }
    setShowModel({
      show: true,
      id: null,
      title: "Transporter Confirm!",
      message:
        "Are you sure want to assign these all order to this Transporter ?",
    });
  };

  if (!props?.is_access) {
    return (
      <>
        <div className="no-access-section">
          <div style={{ height: "25vh" }}></div>
          <img
            src={AccessDenied}
            alt=""
            style={{ width: "20%" }}
          />
          <br />
          <label className="gr">
            You currently does not have access to this page
          </label>
          <br />
          <label className="gr">Please try again latter</label>
        </div>
      </>
    );
  }

  return (
    <>
      {spinner && (
        <div>
          <Loader loading={spinner} />
        </div>
      )}
      <div className="row profile-section">
        <div className="right-head-section">
          <h6 className="page-heading">
            Rider Details{" "}
            <RotateLeftIcon
              className="reload-data-icon"
              onClick={reloadData}
              style={{ color: "#00ab92" }}
            />
          </h6>
          <div className="transporter-list">
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Transporter</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedTransporter}
                label="Transporter"
                onChange={(e) => {
                  setSelectedTransporter(e.target.value);
                }}
                style={{ background: "#e2e5ea" }}
              >
                {transporter &&
                  transporter.map((x) => {
                    return (
                      <MenuItem key={x?._id} value={x?._id}>
                        {x.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </div>
        </div>
        <Col md={12} className="order-outer-section" style={{ padding: 0 }}>
          <Card>
            <Badge badgeContent={rider?.length || 0} color="secondary"></Badge>
            <CardHeader>
              <b>Ready For Return Order</b>
            </CardHeader>
            <CardBody>
              {rider && rider?.length ? (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Action</th>
                      <th>Name</th>
                      <th>Mobile No</th>
                      <th>Distance</th>
                      <th>Address</th>
                      <th>No Of Orders</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rider.map((item, index) => {
                      return (
                        <tr key={item?._id}>
                          <th scope="row">{index + 1}</th>
                          <td>
                            <Checkbox
                              checked={item.checked}
                              style={{ marginTop: "-8px" }}
                              onChange={(e) => {
                                rider[index].checked = e.target.checked;
                                setRider([...rider]);
                              }}
                            />
                          </td>
                          <td
                            className="tag-no-field"
                            onClick={() => {
                              showOrderDetail(item);
                            }}
                          >
                            {item?.name || "NA"}
                          </td>
                          <td>+{item?.mobile_no}</td>
                          <td>{(item?.distance || 0).toFixed(2)} Km</td>
                          <td>{item?.address}</td>
                          <td>{item?.orders?.length || 0} Orders</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <div className="no-data">
                  <label>No Order Available!</label>
                </div>
              )}
            </CardBody>
            <CardFooter>
              {rider && rider?.length > 0 && (
                <Button
                  className="confirm-btn"
                  onClick={() => {
                    onConfirmOrder();
                  }}
                >
                  Assign to Rider
                </Button>
              )}
            </CardFooter>
          </Card>
        </Col>
      </div>

      <Modal size="lg" className="ledger-modal" isOpen={open} toggle={toggle}>
        <ModalHeader>Order Details</ModalHeader>
        <ModalBody className="order-detail-outer">
          {selectedRider?.orders &&
            selectedRider?.orders?.length > 0 &&
            selectedRider?.orders?.map((item) => {
              return (
                <Order
                  item={item}
                  reloadData={reloadData}
                  open={false}
                  setOpen={() => {}}
                  selectedOrder={null}
                  setSelectedOrder={() => {}}
                  key={item?._id}
                />
              );
            })}
          {(!selectedRider?.orders || selectedRider?.orders?.length === 0) && (
            <div className="no-data">
              <img src={NoDataImg} alt="no-data" style={{height: "50vh"}} /><br />
              <label style={{ color: "lightgray" }}>No Data Found</label>
            </div>
          )}
        </ModalBody>
      </Modal>

      <Confirmation
        data={showModal}
        confirmModal={submitConfirmed}
        hideModal={hideConfirmationModal}
      />
    </>
  );
};

export default Rider;
