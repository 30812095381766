import Colors from "../../constants/colors";

const ErrorIcon = ({ color = Colors.black, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.417"
      height="21.85"
      viewBox="0 0 20.417 21.85"
      {...props}
    >
      <g
        id="Group_48869"
        data-name="Group 48869"
        transform="translate(-23970.426 -679.833)"
      >
        <path
          id="Path_112629"
          data-name="Path 112629"
          d="M300.937,287.683a2.081,2.081,0,0,0-2.081-2.081h-2.775a2.081,2.081,0,1,0,0,4.163h2.775a2.081,2.081,0,0,0,2.081-2.081Z"
          transform="translate(23683.166 405.489)"
          fill={color === Colors.black ? Colors.black : color}
          stroke="#f6e2dc"
          strokeWidth="1"
        />
        <path
          id="Path_112630"
          data-name="Path 112630"
          d="M199.84,128.307l7.285,4.163h0a2.081,2.081,0,0,0,2.081,0l7.285-4.163h0a2.081,2.081,0,0,0,1.041-1.807v-8.325a2.081,2.081,0,0,0-1.041-1.807l-7.285-4.163h0a2.081,2.081,0,0,0-2.081,0l-7.285,4.163h0a2.081,2.081,0,0,0-1.041,1.807V126.5a2.081,2.081,0,0,0,1.041,1.807Zm1.04-10.132,7.285-4.163,7.285,4.163V126.5l-7.285,4.163-7.285-4.163Z"
          transform="translate(23772.469 568.407)"
          fill={color === Colors.black ? Colors.black : color}
          stroke="#f6e2dc"
          strokeWidth="1"
        />
        <path
          id="Path_112631"
          data-name="Path 112631"
          d="M279.628,225.212A1.214,1.214,0,1,1,278.414,224a1.214,1.214,0,0,1,1.214,1.214"
          transform="translate(23698.924 463.277)"
          fill={color === Colors.black ? Colors.black : color}
          stroke="#f6e2dc"
          strokeWidth="1"
        />
        <path
          id="Path_112632"
          data-name="Path 112632"
          d="M386.028,225.212A1.214,1.214,0,1,1,384.814,224a1.214,1.214,0,0,1,1.214,1.214"
          transform="translate(23599.115 463.277)"
          fill={color === Colors.black ? Colors.black : color}
          stroke="#f6e2dc"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export default ErrorIcon;
