import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRouter from "../components/privateRouter";
import Login from "../pages/login";
import Forgot from "../pages/forgot";
import Dashboard from "../pages/dashboard";

const MainRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="forgot" element={<Forgot />} />
        <Route
          path="/"
          element={<PrivateRouter children={<Dashboard />} />}
        />
        <Route path="*" element={<PrivateRouter children={<Dashboard />} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default MainRoutes;
