import "./styles.scss";
import NotFoundImg from "../../assets/images/not-found-clipart.png";

const NotFoundPage = () => {
  return (
    <div className="section-404">
      <img src={NotFoundImg} alt="page-not-found" />
    </div>
  );
};

export default NotFoundPage;
