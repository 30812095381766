const Colors = {
  white: "#ffffff",
  black: "#000000",
  blackTransparent: "rgba(0,0,0,0.8)",
  hawkesBlue: "#D5D9E0",
  aliceBlue: "#F7FAFF",
  mediumSlateBlue: "#6E62E3",
  scarletRed: "#FF1F1F",
  dimGray: "#707070",
  whisper: "#EDEDED",
  lavender: "#EFEFFA",
  whiteIce: "#D5FFED",
  roseWhite: "#FFEFEA",
  atomicTangerine: "#FF8F6B",
  cornSilk: "#FFF6DD",
  kournikova: "#FFD24D",
  coral: "#FF7A4F",
  shamrock: "#29CB86",
  saffron: "#F3C029",
  nero: "#242424",
  grey: "#797979",
  whiteSmoke: "#F5F5F5",
  portage: "#9092FA",
  bananaMania: "#FFEDB5",
  veryLightGrey: "#CBCBCB",
  darkGray: "#A3A3A3",
  horsesNeck: "#6E5916",
  eclipse: "#383838",
  greySuit: "#8D8C91",
  ghostWhite: "#F7F6FF",
  solitude: "#EDF0FA",
};

export default Colors;
