import { createSlice } from "@reduxjs/toolkit";
import { loginAddedUsers, forgotpasswordLink } from "./actions";

const initialState = {
  accessToken: null,
  refreshToken: null,
  user_data: {},
};

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setInitialState: () => {
      console.log('called setInitialState');

      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginAddedUsers.fulfilled, (state, { payload }) => {
      const { status, data } = payload;
      console.log(status, data)
      if (status === 200 && data.status) {
        return {
          ...state,
          ...data,
        };
      } else {
        return initialState;
      }
    });
    builder.addCase(loginAddedUsers.rejected, () => {
      console.log("rejected")
      return initialState;
    });
  },
});

export const { setInitialState } = AuthSlice.actions;

export { loginAddedUsers, forgotpasswordLink };
export default AuthSlice.reducer;
