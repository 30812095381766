import api from "../api";
import userEndpoint from "./config";

const userApi = {
  async getOneUser() {
    return await api.get(userEndpoint.commonUrl);
  },

  async getQRCode() {
    return await api.get(userEndpoint.qrUrl);
  },

  async getAllRider() {
    return await api.get(userEndpoint.riderUrl);
  },

  async getAllTransporter() {
    return await api.get(userEndpoint.transporterUrl);
  },

  async getAllExpressRider() {
    return await api.get(userEndpoint.expressUrl);
  },
};

export default userApi;
