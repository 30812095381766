import { useState, useEffect, useContext } from "react";
import "./styles.scss";
import ToastContext from "../../components/toast/toastContext";
import Loader from "../../components/loader";
import Confirmation from "../../components/confirmation";
import {
  Row,
  Col,
  CardBody,
  CardFooter,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  Button,
  CardHeader,
} from "reactstrap";
import moment from "moment/moment";
import Barcode from "react-barcode";
import order from "../../apis/order";
import Badge from "@mui/material/Badge";
import TextField from "@mui/material/TextField";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import Checkbox from "@mui/material/Checkbox";
import user from "../../apis/user";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import NoDataImg from "../../assets/images/no-data.jpg";
import AccessDenied from "../../assets/images/access-denied.jpg";
import { useNavigate } from "react-router-dom";
import Order from "../order";

const Express = (props) => {
  const navigate = useNavigate();
  const { toastMessage } = useContext(ToastContext);
  const [spinner, setSpinner] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [expressPickOrder, setExpressPickOrder] = useState([]);
  const [confirmOrder, setConfirmOrder] = useState([]);
  const [waitingOrder, setWaitingOrder] = useState([]);
  const [selectedBarCode, setSelectedBarCode] = useState([]);
  const [selectedTag, setSelectedTag] = useState("");
  const [showModal, setShowModel] = useState({
    show: false, // initial values set to false and null
    id: null,
    title: "Order Confirm",
    message: "",
  });
  const [rider, setRider] = useState([]);
  const [selectedRider, setSelectedRider] = useState("");
  const [receivedOrder, setReceivedOrder] = useState([]);
  const [readyOrder, setReadyOrder] = useState([]);

  const getServiceType = (type) => {
    let name = "";
    switch (type) {
      case "WASH_AND_IRON":
        name = "WI";
        break;
      case "WASH_AND_FOLD":
        name = "WF";
        break;
      case "IRON_AND_FOLD":
        name = "IF";
        break;
      case "DRY_CLEAN":
        name = "DC";
        break;
      default:
        name = "NA";
    }
    return name;
  };

  const getExpressPickOrder = async () => {
    try {
      setSpinner(true);
      let query = `?sortBy=pick_date&sortOrder=asc`;
      const response = await order.getExpressPickOrder(query);
      setExpressPickOrder(response?.data?.data.filter((x) => !x.delivery_id));
      setSpinner(false);
    } catch (err) {
      setSpinner(false);
    }
  };

  const getExpressWaitingData = async () => {
    try {
      setSpinner(true);
      let query = `?sortBy=drop_date&sortOrder=asc`;
      const response = await order.getExpressWaitingOrder(query);
      setWaitingOrder(response?.data?.data);
      setSpinner(false);
    } catch (err) {
      setSpinner(false);
    }
  };

  const getConfirmedData = async () => {
    try {
      setSpinner(true);
      let query = `?sortBy=drop_date&sortOrder=asc`;
      const response = await order.getExpressConfirmedOrder(query);
      setConfirmOrder(response?.data?.data);
      setSpinner(false);
    } catch (err) {
      setSpinner(false);
    }
  };

  const getRiderData = async () => {
    try {
      setSpinner(true);
      const response = await user.getAllExpressRider();
      setRider(response?.data?.data);
      setSpinner(false);
    } catch (err) {
      setSpinner(false);
    }
  };

  const getReceivedOrder = async () => {
    try {
      setSpinner(true);
      let query = `?sortBy=drop_date&sortOrder=asc`;
      const response = await order.getExpressReceivedOrder(query);
      setReceivedOrder(response?.data?.data);
      setSpinner(false);
    } catch (err) {
      setSpinner(false);
    }
  };

  const getReadyOrder = async () => {
    try {
      setSpinner(true);
      let query = `?sortBy=drop_date&sortOrder=asc`;
      const response = await order.getExpressReadyOrder(query);
      setReadyOrder(response?.data?.data);
      setSpinner(false);
    } catch (err) {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getRiderData();
    getExpressPickOrder();
    getExpressWaitingData();
    getConfirmedData();
    getReceivedOrder();
    getReadyOrder();
  }, []);

  const reloadData = () => {
    getExpressPickOrder();
    getExpressWaitingData();
    getConfirmedData();
    getReceivedOrder();
    getReadyOrder();
  };

  const toggle = () => {
    setOpen(!open);
    setSelectedOrder(null);
  };

  const showOrderDetail = (item) => {
    setSelectedOrder(item);
    if (item?.is_barcode) {
      let arr = [];
      item?.services?.map((x) => {
        x.barcode?.map((b) => {
          arr.push({
            barcode: b,
            type: getServiceType(x?.type),
          });
          return true;
        });
        return true;
      });
      setSelectedBarCode(arr);
    }
    setOpen(true);
  };

  let options = {
    width: 1.2,
    height: 30,
    format: "CODE128",
    displayValue: true,
    // font: "monospace",
    // fontOptions: "bold",
    fontSize: 21,
    textAlign: "center",
    textPosition: "bottom",
    textMargin: 4,
    background: "#ffffff",
    lineColor: "#000000",
    margin: 10,
  };

  const onConfirmOrder = async () => {
    setShowModel({
      show: true,
      id: null,
      title: "Order Confirm!",
      message: "Are you sure want to confirm these all orders ?",
    });
  };

  const hideConfirmationModal = () => {
    setShowModel({
      show: false,
      id: null,
      title: "",
      message: "",
    });
  };

  const submitConfirmed = () => {
    setShowModel({
      show: false,
      id: null,
      title: "",
      message: "",
    });
    if (selectedOrder?.status === "RECEIVED_BY_WAREHOUSE") {
      if (!selectedOrder?.is_barcode) {
        acceptOrder();
      } else {
        finalConfirmOrder();
      }
    } else if (selectedOrder?.status === "READY_FOR_SERVICE") {
      readyForReturnOrder();
    } else {
      acceptOrder();
    }
  };

  const acceptOrder = async () => {
    try {
      setSpinner(true);
      await order.acceptExpressOrder();
      setSpinner(false);
      toastMessage("success", "All Orders Confirmed successfully!");
      reloadData();
    } catch (err) {
      setSpinner(false);
    }
  };

  const finalConfirmOrder = async () => {
    try {
      setSpinner(true);
      await order.readyOrder(selectedOrder?._id);
      setSpinner(false);
      toastMessage("success", "Order successfully ready for service!");
      setOpen(false);
      reloadData();
    } catch (err) {
      setSpinner(false);
    }
  };

  const generateBarCode = async () => {
    try {
      setSpinner(true);
      const response = await order.generateOrderBarCode(selectedOrder?._id);
      if (response?.data?.data) {
        setSelectedOrder(response?.data?.data);
        let arr = [];
        response?.data?.data?.services?.map((x) => {
          x.barcode?.map((b) => {
            arr.push({
              barcode: b,
              type: getServiceType(x?.type),
            });
            return true;
          });
          return true;
        });
        setSelectedBarCode(arr);
      }
      reloadData();
      setSpinner(false);
    } catch (err) {
      setSpinner(false);
    }
  };

  const readyForReturnOrder = async () => {
    try {
      setSpinner(true);
      await order.readyForReturnOrder(selectedOrder?._id);
      setSpinner(false);
      toastMessage("success", "Order successfully ready for return!");
      setOpen(false);
      reloadData();
    } catch (err) {
      setSpinner(false);
    }
  };

  const onConfirmOrderReady = async () => {
    if (selectedOrder?.status === "RECEIVED_BY_WAREHOUSE") {
      setShowModel({
        show: true,
        id: null,
        title: "Order Confirm!",
        message:
          "Make sure all barcode attached with cloths according to services, you cannot rollback this order ?",
      });
    } else if (selectedOrder?.status === "READY_FOR_SERVICE") {
      setShowModel({
        show: true,
        id: null,
        title: "Order Confirm!",
        message:
          "Make sure all items are available for this order and successfully packed ?",
      });
    }
  };

  const onPrintBarcode = () => {
    var container = document.getElementById("div-svg");
    var width = "1000";
    var height = "600";
    var printWindow = window.open(
      "",
      "PrintMap",
      "width=" + width + ",height=" + height
    );
    printWindow.document.writeln(container.innerHTML);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      if (!e.target.value) return;
      const arr = [
        ...waitingOrder,
        ...confirmOrder,
        ...readyOrder,
        ...receivedOrder,
      ];
      const item = arr.find((x) => x.tag_no === e.target.value);
      if (!item) return;
      setSelectedOrder(item);
      setSelectedTag("");
      setOpen(true);
    }
  };

  const getOrderClass = (drop_date) => {
    const todayStart = moment().startOf("day");
    const todayEnd = moment().endOf("day");
    if (new Date(drop_date).getTime() < new Date(todayStart).getTime()) {
      return "expire-drop-order";
    } else if (
      new Date(drop_date).getTime() >= new Date(todayStart).getTime() &&
      new Date(drop_date).getTime() <= new Date(todayEnd).getTime()
    ) {
      return "today-drop-order";
    }
    return "";
  };

  const AssignToRider = async () => {
    if (!expressPickOrder.filter((x) => x.checked).length) {
      return toastMessage("error", "Please select order from list");
    }
    if (!selectedRider) {
      return toastMessage("error", "Please select rider from list");
    }
    try {
      setSpinner(true);
      let orderIds = [];
      expressPickOrder
        .filter((x) => x.checked)
        .map((x) => {
          orderIds.push(x?._id);
          return true;
        });
      await order.assignToRider(orderIds, selectedRider);
      toastMessage(
        "success",
        "Selected Orders successfully assigned to Rider!"
      );
      setSpinner(false);
      reloadData();
    } catch (err) {
      setSpinner(false);
    }
  };

  const AssignToRiderForReturn = async () => {
    if (!readyOrder.filter((x) => x.checked).length) {
      return toastMessage("error", "Please select order from list");
    }
    if (!selectedRider) {
      return toastMessage("error", "Please select rider from list");
    }
    try {
      setSpinner(true);
      let orderIds = [];
      readyOrder
        .filter((x) => x.checked)
        .map((x) => {
          orderIds.push(x?._id);
          return true;
        });
      await order.assignToRiderFromWarehouse(orderIds, selectedRider);
      toastMessage(
        "success",
        "Selected Orders successfully assigned to Rider!"
      );
      setSpinner(false);
      reloadData();
    } catch (err) {
      setSpinner(false);
    }
  };

  const ExpressOrderRow = (item, index, type = "") => {
    return (
      <div key={item?._id} style={{ position: "relative" }}>
        {type === "request" && (
          <Checkbox
            checked={item.checked}
            style={{
              marginTop: "-11px",
              marginLeft: "-11px",
              position: "absolute",
              background: "white",
              padding: 0,
            }}
            onChange={(e) => {
              expressPickOrder[index].checked = e.target.checked;
              setExpressPickOrder([...expressPickOrder]);
            }}
          />
        )}
        {type === "ready" && (
          <Checkbox
            checked={item.checked}
            style={{
              marginTop: "-11px",
              marginLeft: "-11px",
              position: "absolute",
              background: "white",
              padding: 0,
            }}
            onChange={(e) => {
              readyOrder[index].checked = e.target.checked;
              setReadyOrder([...readyOrder]);
            }}
          />
        )}
        <Order
          item={item}
          reloadData={reloadData}
          open={open}
          setOpen={setOpen}
          selectedOrder={selectedOrder}
          setSelectedOrder={setSelectedOrder}
          key={item?._id}
          rider={rider}
        />
        {/* <div
          className={`order-row ${getOrderClass(item?.drop_date)}`}
          onClick={() => {
            showOrderDetail(item);
          }}
        >
          <p>
            <label className="order-tag">#{item?.tag_no || "NA"}</label>
            <label className="order-created">
              {moment(item?.createdAt).format("DD-MMM-YYYY")}
            </label>
          </p>
          <p>
            <label className="order-delivery">
              Delivery Date:{" "}
              <span
                style={{
                  color: "black",
                  fontWeight: "600",
                }}
              >
                {moment(item?.drop_date).format("DD-MMM-YYYY")}{" "}
                {item?.drop_time_slot}
              </span>
            </label>
            <label className="order-qty">
              Qty:{" "}
              <span
                style={{
                  color: "black",
                  fontWeight: "600",
                }}
              >
                {item?.quantity || 0}
              </span>
            </label>
          </p>
          <p className="order-type">
            <label>
              Order Type:{" "}
              <span
                style={{
                  color: "black",
                  fontWeight: "600",
                }}
              >
                {item?.type}
              </span>
            </label>
            <label className="order-qty">
              Order ID:{" "}
              <span
                style={{
                  color: "black",
                  fontWeight: "600",
                }}
              >
                {item?.order_id || "NA"}
              </span>
            </label>
          </p>
          <p className="order-message">
            {selectedOrder?.comment || "No message from customer"}
          </p>
        </div> */}
      </div>
    );
  };

  if (!props?.is_access) {
    return (
      <>
        <div className="no-access-section">
          <div style={{ height: "25vh" }}></div>
          <img src={AccessDenied} alt="" style={{ width: "20%" }} />
          <br />
          <label className="gr">
            You currently does not have access to this page
          </label>
          <br />
          <label className="gr">Please try again latter</label>
        </div>
      </>
    );
  }

  return (
    <>
      {spinner && (
        <div>
          <Loader loading={spinner} />
        </div>
      )}
      <div className="row profile-section">
        <div className="right-head-section">
          <h6 className="page-heading">
            Express Order Detail{" "}
            <RotateLeftIcon
              className="reload-data-icon"
              onClick={reloadData}
              style={{ color: "#00ab92" }}
            />
          </h6>
          <div className="transporter-list">
            <TextField
              type="search"
              id="outlined-basic"
              variant="outlined"
              fullWidth
              size="small"
              value={selectedTag}
              label="Search order..."
              style={{ background: "#F7F7F7", borderColor: "#F7F7F7" }}
              onChange={(e) => {
                setSelectedTag(e.target.value);
              }}
              onKeyDown={keyPress}
            />
          </div>
        </div>
        <Col md={6} className="order-outer-section" style={{ paddingLeft: 0 }}>
          <Card>
            <Badge
              badgeContent={expressPickOrder?.length || 0}
              color="secondary"
            ></Badge>
            <CardHeader>
              <b>Waiting For Express Rider</b>
            </CardHeader>
            <CardBody>
              {expressPickOrder &&
                expressPickOrder?.length > 0 &&
                expressPickOrder
                  ?.filter(
                    (item) =>
                      item?.tag_no.toLowerCase().includes(selectedTag) ||
                      item?.order_id.toLowerCase().includes(selectedTag) ||
                      item?.customer?.name.toLowerCase().includes(selectedTag) ||
                      item?.customer?.mobile_no.toString().includes(selectedTag)
                  )
                  .map((item, index) => {
                    return ExpressOrderRow(item, index, "request");
                  })}
              {(!expressPickOrder || expressPickOrder?.length === 0) && (
                <div className="no-data">
                  <img
                    src={NoDataImg}
                    alt="no-data"
                    style={{ height: "50vh" }}
                  />
                  <br />
                  <label style={{ color: "lightgray" }}>No Data Found</label>
                </div>
              )}
            </CardBody>
            {expressPickOrder && expressPickOrder?.length > 0 && (
              <CardFooter>
                <FormControl size="small" style={{ width: "70%" }}>
                  <InputLabel id="demo-simple-select-label">Rider</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedRider}
                    label="Transporter"
                    onChange={(e) => {
                      setSelectedRider(e.target.value);
                    }}
                  >
                    {rider &&
                      rider.map((x) => {
                        return <MenuItem value={x?._id}>{x.name}</MenuItem>;
                      })}
                  </Select>
                </FormControl>
                <Button
                  className="confirm-btn"
                  onClick={() => {
                    AssignToRider();
                  }}
                >
                  Assign
                </Button>
              </CardFooter>
            )}
          </Card>
        </Col>
        <Col md={6} className="order-outer-section" style={{ paddingRight: 0 }}>
          <Card>
            <Badge
              badgeContent={waitingOrder?.length || 0}
              color="secondary"
            ></Badge>
            <CardHeader>
              <b>Waiting Confirmed Order</b>
            </CardHeader>
            <CardBody>
              {waitingOrder &&
                waitingOrder?.length > 0 &&
                waitingOrder
                  ?.filter(
                    (item) =>
                      item?.tag_no.toLowerCase().includes(selectedTag) ||
                      item?.order_id.toLowerCase().includes(selectedTag) ||
                      item?.customer?.name.toLowerCase().includes(selectedTag) ||
                      item?.customer?.mobile_no.toString().includes(selectedTag)
                  )
                  .map((item, index) => {
                    return ExpressOrderRow(item, index, "waiting");
                  })}
              {(!waitingOrder || waitingOrder?.length === 0) && (
                <div className="no-data">
                  <img
                    src={NoDataImg}
                    alt="no-data"
                    style={{ height: "50vh" }}
                  />
                  <br />
                  <label style={{ color: "lightgray" }}>No Data Found</label>
                </div>
              )}
            </CardBody>
            {waitingOrder && waitingOrder?.length > 0 && (
              <CardFooter>
                <Button
                  className="confirm-btn"
                  onClick={() => {
                    onConfirmOrder();
                  }}
                >
                  Confirm
                </Button>
              </CardFooter>
            )}
          </Card>
        </Col>
        <Col md={6} className="order-outer-section" style={{ paddingLeft: 0 }}>
          <Card>
            <Badge
              badgeContent={confirmOrder?.length || 0}
              color="secondary"
            ></Badge>
            <CardHeader>
              <b>Confirmed Order</b>
            </CardHeader>
            <CardBody>
              {confirmOrder &&
                confirmOrder?.length > 0 &&
                confirmOrder
                  ?.filter(
                    (item) =>
                      item?.tag_no.toLowerCase().includes(selectedTag) ||
                      item?.order_id.toLowerCase().includes(selectedTag) ||
                      item?.customer?.name.toLowerCase().includes(selectedTag) ||
                      item?.customer?.mobile_no.toString().includes(selectedTag)
                  )
                  .map((item, index) => {
                    return ExpressOrderRow(item, index, "confirmed");
                  })}
              {(!confirmOrder || confirmOrder?.length === 0) && (
                <div className="no-data">
                  <img
                    src={NoDataImg}
                    alt="no-data"
                    style={{ height: "50vh" }}
                  />
                  <br />
                  <label style={{ color: "lightgray" }}>No Data Found</label>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
        <Col md={12}>
          <br />
        </Col>
        <Col md={6} className="order-outer-section" style={{ paddingLeft: 0 }}>
          <Card>
            <Badge
              badgeContent={receivedOrder?.length || 0}
              color="secondary"
            ></Badge>
            <CardHeader>
              <b>Waiting For Return Order</b>
            </CardHeader>
            <CardBody>
              {receivedOrder &&
                receivedOrder?.length > 0 &&
                receivedOrder
                  ?.filter(
                    (item) =>
                      item?.tag_no.toLowerCase().includes(selectedTag) ||
                      item?.order_id.toLowerCase().includes(selectedTag) ||
                      item?.customer?.name.toLowerCase().includes(selectedTag) ||
                      item?.customer?.mobile_no.toString().includes(selectedTag)
                  )
                  .map((item, index) => {
                    return ExpressOrderRow(item, index, "");
                  })}
              {(!receivedOrder || receivedOrder?.length === 0) && (
                <div className="no-data">
                  <img
                    src={NoDataImg}
                    alt="no-data"
                    style={{ height: "50vh" }}
                  />
                  <br />
                  <label style={{ color: "lightgray" }}>No Data Found</label>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
        <Col md={6} className="order-outer-section" style={{ paddingRight: 0 }}>
          <Card>
            <Badge
              badgeContent={readyOrder?.length || 0}
              color="secondary"
            ></Badge>
            <CardHeader>
              <b>Ready For Return Order</b>
            </CardHeader>
            <CardBody>
              {readyOrder &&
                readyOrder?.length > 0 &&
                readyOrder
                  ?.filter(
                    (item) =>
                      item?.tag_no.toLowerCase().includes(selectedTag) ||
                      item?.order_id.toLowerCase().includes(selectedTag) ||
                      item?.customer?.name.toLowerCase().includes(selectedTag) ||
                      item?.customer?.mobile_no.toString().includes(selectedTag)
                  )
                  .map((item, index) => {
                    return ExpressOrderRow(item, index, "ready");
                  })}
              {(!readyOrder || readyOrder?.length === 0) && (
                <div className="no-data">
                  <img
                    src={NoDataImg}
                    alt="no-data"
                    style={{ height: "50vh" }}
                  />
                  <br />
                  <label style={{ color: "lightgray" }}>No Data Found</label>
                </div>
              )}
            </CardBody>
            {readyOrder && readyOrder?.length > 0 && (
              <CardFooter>
                <FormControl size="small" style={{ width: "50%" }}>
                  <InputLabel id="demo-simple-select-label">Rider</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedRider}
                    label="Transporter"
                    onChange={(e) => {
                      setSelectedRider(e.target.value);
                    }}
                  >
                    {rider &&
                      rider.map((x) => {
                        return <MenuItem value={x?._id}>{x.name}</MenuItem>;
                      })}
                  </Select>
                </FormControl>
                <Button
                  className="confirm-btn"
                  onClick={() => {
                    AssignToRiderForReturn();
                  }}
                >
                  Assign To Rider
                </Button>
              </CardFooter>
            )}
          </Card>
        </Col>
      </div>

      <Modal size="lg" className="ledger-modal" isOpen={open} toggle={toggle}>
        <ModalHeader>Order Details</ModalHeader>
        <ModalBody className="order-detail-outer">
          <label className="main-heading">BASIC DETAIL</label>
          <Row>
            <Col>
              <label className="heading">Tag No</label>
              <p className="heading-val">{selectedOrder?.tag_no}</p>
            </Col>
            <Col>
              <label className="heading">Type</label>
              <p className="heading-val">{selectedOrder?.type}</p>
            </Col>
            <Col>
              <label className="heading">Drop Date & Time</label>
              <p className="heading-val">
                {moment(selectedOrder?.drop_date).format("DD-MMM-YYYY")}
              </p>
              <p className="heading-val">{selectedOrder?.drop_time_slot}</p>
            </Col>
            <Col>
              <label className="heading">Quantity</label>
              <p className="heading-val">{selectedOrder?.quantity} Items</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="heading">Message</label>
              <p className="heading-val">
                {selectedOrder?.comment || "No message from customer"}
              </p>
            </Col>
          </Row>
          <hr />
          <label className="main-heading">SERVICE DETAIL</label>
          <Row>
            <Col>
              <label className="heading">Name</label>
            </Col>
            <Col>
              <label className="heading">Quantity</label>
            </Col>
            <Col>
              <label className="heading">Weight</label>
            </Col>
          </Row>
          {selectedOrder?.services &&
            selectedOrder?.services.map((item) => {
              return (
                <Row>
                  <Col md={4}>
                    <p className="heading-val">{item?.name}</p>
                  </Col>
                  <Col md={4}>
                    <p className="heading-val">{item?.quantity} Items</p>
                  </Col>
                  <Col md={4}>
                    <p className="heading-val">
                      {Number(item?.weight).toFixed(2)} Kg
                    </p>
                  </Col>
                  <Row style={{ marginBottom: "15px" }}>
                    {selectedOrder?.is_barcode &&
                      item.barcode?.map((b) => {
                        return (
                          <span className="barcode-label">
                            <Barcode {...options} value={b} />
                          </span>
                        );
                      })}
                  </Row>
                </Row>
              );
            })}

          {selectedBarCode && selectedBarCode?.length > 0 && (
            <Row id="div-svg" style={{ visibility: "visible" }}>
              {selectedBarCode?.map((b, index) => {
                return (
                  <div
                    style={{
                      textAlign: "center",
                      padding: "15px 0",
                      borderBottom: "0.8px dashed black",
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {selectedOrder?.customer?.name || "NA"}
                    </p>
                    <Barcode {...options} value={b?.barcode} />
                    <p
                      style={{
                        fontSize: "10px",
                        margin: 0,
                        padding: 0,
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      {moment().format("DD-MMM-YYYY")}
                    </p>
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      Garments {index + 1}/{selectedBarCode?.length} ({b?.type})
                    </p>
                  </div>
                );
              })}
            </Row>
          )}

          {selectedOrder?.status === "RECEIVED_BY_WAREHOUSE" &&
            !selectedOrder?.is_barcode && (
              <Button
                className="confirm-btn"
                style={{ float: "right" }}
                onClick={() => {
                  generateBarCode();
                }}
              >
                Generate Bar Code
              </Button>
            )}

          {selectedOrder?.status === "RECEIVED_BY_WAREHOUSE" &&
            selectedOrder?.is_barcode && (
              <Button
                className="confirm-btn"
                style={{ float: "right" }}
                onClick={() => {
                  onConfirmOrderReady();
                }}
              >
                Confirm Ready
              </Button>
            )}

          {selectedOrder?.status === "READY_FOR_SERVICE" && (
            <Button
              className="confirm-btn"
              style={{ float: "right" }}
              onClick={() => {
                onConfirmOrderReady();
              }}
            >
              Confirm Ready
            </Button>
          )}

          {selectedOrder?.is_barcode && (
            <Button
              className="print-btn"
              style={{ float: "right" }}
              onClick={() => {
                onPrintBarcode();
              }}
            >
              Print BarCode
            </Button>
          )}
        </ModalBody>
      </Modal>

      <Confirmation
        data={showModal}
        confirmModal={submitConfirmed}
        hideModal={hideConfirmationModal}
      />
    </>
  );
};

export default Express;
