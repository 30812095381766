import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DashboardIcon from "../../../assets/icons/list.png";
// import { useDispatch } from "react-redux";
// import { setInitialState } from "../../../store/reducer/auth";
// import { setLoader } from "../../../store/reducer/ui";
// import auth from "../../../apis/auth";

import Text from "../../../components/text";
import "./styles.scss";

const Item = ({
  icon,
  text,
  extraText,
  onClick,
  active = false,
  isChildren = false,
  endIcon,
}) => {
  return (
    <div
      onClick={onClick}
      className={`sidebar-item-container ${active ? "bg-whiteSmoke" : ""}`}
    >
      <div className="sidebar-item-icon-container">{icon}</div>
      <Text className="fs-14 fw-b" value={text} />
      {extraText && (
        <div className="sidebar-number-container">
          <Text className="fs-10 fw-eb fc-white" value={extraText} />
        </div>
      )}
      {isChildren && (
        <div className="sidebar-item-icon-container">{endIcon}</div>
      )}
    </div>
  );
};

const SideBar = ({ user_data, data }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isNavOpen, setIsNavOpen] = useState(false);
  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const onClickItem = (route, type = "parent") => {
    toggleNav();
    navigate(route);
  };

  // const onClickLogout = async () => {
  //   dispatch(setLoader(true));
  //   await auth
  //     .logoutUser()
  //     .then((res) => {
  //       dispatch(setInitialState());
  //       navigate("/login");
  //     })
  //     .catch((err) => {
  //       dispatch(setInitialState());
  //       navigate("/login");
  //     });
  //   dispatch(setLoader(false));
  // };

  useEffect(() => {}, []);

  return (
    <div
      className={isNavOpen ? "sidebar-main-container" : "hide-nav-main"}
      style={{ position: "relative" }}
    >
      <div style={{ textAlign: "right" }}>
        <img
          className={isNavOpen ? "list-icon" : "list-icon-hide"}
          src={DashboardIcon}
          width={20}
          onClick={toggleNav}
        />
      </div>
      {isNavOpen && (
        <>
          <Item
            active={location.pathname === "/" ? true : false}
            text="Dashboard"
            // icon={<img src={DashboardIcon} width={20} />}
            onClick={() => onClickItem("/")}
          />
          {data.map((item) => {
            if (user_data?.user_data?.menu_permissions?.[item?.id]) {
              return (
                <Item
                  active={location.pathname === item?.url ? true : false}
                  text={item?.label}
                  // icon={<img src={DashboardIcon} width={20} />}
                  key={item?.label}
                  onClick={() => onClickItem(item?.url)}
                />
              );
            }
          })}
        </>
      )}
    </div>
  );
};

export default SideBar;
