import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import api from "../../../apis/api";
import "./index.css";
import { Link } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
// import GoogleAddress from "../../../utils/googleAddress";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    padding: 9,
  },
});
const CssTextArea = styled(TextareaAutosize)({
  "input#comment-text": {
    width: "100%",
    height: "4.3rem",
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function UserForm(props) {
  // const history = useHistory();
  const { id } = useParams("id");
  const [data, setData] = React.useState({
    name: "",
    country_code: "91",
    mobile_no: "91",
    email_id: "",
    role: "",
    vehicle_id: "",
    vehicle_number: "",
  });
  const [enablemerchant, setenablemerchant] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [update, setupdate] = useState(false);
  const [roleArr, setRoleArr] = useState([]);
  const [aadharFile, setAadharFile] = useState(null);
  const [panFile, setPanFile] = useState(null);
  const [licenseFile, setLicenseFile] = useState(null);
  const [profileFile, setProfileFile] = useState(null);

  const [center, setCenter] = useState({
    lat: 37.7749,
    lng: -122.4194,
  });
  const [warehouse, setWarehouse] = useState([]);
  const [vehicle, setVehicle] = useState([]);

  const [warehouseMenu, setWarehouseMenu] = useState({
    Dashboard: true,
    Receiver: true,
    Deliver: true,
    Express: true,
    Rider: true,
    Offline_customer: true,
    Detect_order: true,
    Location: true,
    Order: true,
  });

  useEffect(() => {
    if (id == -1) {
      setData({
        name: "",
        country_code: "91",
        mobile_no: "91",
        email_id: "",
        role: "",
        vehicle_id: "",
        vehicle_number: "",
      });
      // getUserLocation();
    } else {
      api.get(`/user/${id}`).then((response) => {
        setData(response?.data?.data || {});
        if (response.data.data?.menu_permission) {
          setWarehouseMenu(response.data.data?.menu_permission);
        }
        if (response.data.data?.lat && response.data.data?.lng) {
          setCenter({
            lat: response.data.data?.lat,
            lng: response.data.data?.lng,
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    api.get(`/role`).then((response) => {
      setRoleArr(response?.data?.data.filter((x) => x.name !== "ADMIN") || []);
    });
  }, []);

  useEffect(() => {
    api.get(`/warehouse?pageNo=0&rowsPerPage=1000`).then((response) => {
      setWarehouse(response?.data?.data || []);
    });
  }, []);

  const getAllVehicle = () => {
    api.get(`/vehicle`).then((response) => {
      setVehicle(response?.data?.data || []);
    });
  };

  useEffect(() => {
    getAllVehicle();
  }, []);

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
  };

  const save = async () => {
    if (profileFile) {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      const formData = new FormData();
      formData.append("file", profileFile);
      const res = await api.post(`/uploadImages`, formData, config);
      data.profile = res?.data?.file_name;
    }
    if (aadharFile) {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      const formData = new FormData();
      formData.append("file", aadharFile);
      const res = await api.post(`/uploadImages`, formData, config);
      data.documents.aadhar_url = res?.data?.file_name;
    }
    if (panFile) {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      const formData = new FormData();
      formData.append("file", panFile);
      const res = await api.post(`/uploadImages`, formData, config);
      data.documents.pan_url = res?.data?.file_name;
    }
    if (licenseFile) {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      const formData = new FormData();
      formData.append("file", licenseFile);
      const res = await api.post(`/uploadImages`, formData, config);
      data.documents.license_url = res?.data?.file_name;
    }
    setData({ ...data });
    if (data?.role === "WAREHOUSE") {
      data.menu_permission = warehouseMenu;
    } else {
      data.menu_permission = null;
    }
    if (id == -1) {
      api
        .post(`/user`, data)
        .then((response) => {
          setupdate(!update);
          // toast("User Added Successfully.", {
          //   type: "success",
          //   autoClose: 2000,
          // });
          // history.push("/user");
        })
        .catch((err) => {
          console.log(err);
          setupdate(!update);
          // return toast(
          //   err?.response?.data?.message || "Something went wrong!",
          //   {
          //     type: "error",
          //     autoClose: 2000,
          //   }
          // );
        });
    } else {
      api
        .put(`/user/${id}`, data)
        .then((response) => {
          setupdate(!update);
          // toast("User Updated Successfully.", {
          //   type: "success",
          //   autoClose: 2000,
          // });
          // history.push("/user");
        })
        .catch((err) => {
          console.log(err);
          setupdate(!update);
          // return toast(
          //   err?.response?.data?.message || "Something went wrong!",
          //   {
          //     type: "error",
          //     autoClose: 2000,
          //   }
          // );
        });
    }
  };

  const openGoogleMap = () => {
    setShowMap(true);
  };

  const closeGoogleMap = () => {
    setShowMap(false);
  };

  const onSubmitAddress = async () => {
    api
      .post(`/google/full-address`, {
        lat: center?.lat,
        lng: center?.lng,
      })
      .then((response) => {
        setData({
          ...data,
          address: response.data.location,
          lat: center?.lat,
          lng: center?.lng,
        });
        setShowMap(false);
      });
  };

  const onChangeInput = (val, key) => {
    setData({
      ...data,
      [key]: val,
    });
  };

  if (loading) {
    return (
      <div className="no_data_found loader_data_tbl">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <div className="right-head-section">
        <h6 className="page-heading">Users Form</h6>
      </div>
      <div className="outer-section" style={{ width: "100%" }}>
        <div className="merchant_list_main_div">
          <div style={{ padding: "15px" }}>
            {loading ? (
              <div className="no_data_found loader_data_tbl">
                <CircularProgress />
              </div>
            ) : (
              <>
                <h4 className="label-head">BASIC DETAIL</h4>
                <hr />
                <Grid container spacing={2} className="merchant_main_grid">
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="merchant_grid_sec"
                  >
                    <TextField
                      id="new-menu"
                      className="create_merchant_input_feild"
                      variant="outlined"
                      placeholder="Name"
                      fullWidth
                      size="small"
                      inputProps={{
                        maxLength: 15,
                      }}
                      value={data?.name}
                      required
                      onChange={(e) => onChangeInput(e.target.value, "name")}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment className="create_merchn_inpt_icon">
                            <IconButton color="primary">
                              <PersonOutlinedIcon className="merchant_inpt_icon" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <TextField
                      type="number"
                      id="new-menu"
                      className="create_merchant_input_feild"
                      variant="outlined"
                      placeholder="Mobile No"
                      fullWidth
                      size="small"
                      inputProps={{
                        maxLength: 15,
                      }}
                      value={data?.mobile_no}
                      required
                      onChange={(e) =>
                        onChangeInput(e.target.value, "mobile_no")
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <TextField
                      id="new-menu"
                      type="email"
                      className="create_merchant_input_feild"
                      variant="outlined"
                      placeholder="User Email"
                      fullWidth
                      size="small"
                      value={data?.email_id}
                      required
                      onChange={(e) =>
                        onChangeInput(e.target.value, "email_id")
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment className="create_merchn_inpt_icon">
                            <IconButton color="primary">
                              <EmailIcon className="merchant_inpt_icon" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Select
                      name="key1"
                      labelId="country-lbl"
                      label=""
                      id="country"
                      value={data?.role || ""}
                      style={{ width: "100%" }}
                      size="small"
                      onChange={(e) => onChangeInput(e.target.value, "role")}
                    >
                      <MenuItem key={""} value={""}>
                        Select Role
                      </MenuItem>
                      {roleArr &&
                        roleArr.map((c) => {
                          return (
                            <MenuItem key={c._id} value={c.name}>
                              {c.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="merchant_grid_sec"
                  >
                    <div className="sep_inpt_sec">
                      <p>Profile</p>

                      <div className="inp_sep_div">
                        <CssTextField
                          id="new-menu"
                          className="create_merchant_input_feild"
                          variant="outlined"
                          placeholder="aadhar"
                          type="file"
                          fullWidth
                          required
                          onChange={(e) => {
                            setProfileFile(e.target.files[0]);
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <div className="sep_inpt_sec">
                      <p>
                        Address{" "}
                        <LocationOnIcon
                          onClick={openGoogleMap}
                          style={{
                            cursor: "pointer",
                            color: "blue",
                          }}
                        />
                      </p>

                      <div className="inp_sep_div">
                        <CssTextArea
                          id="text"
                          variant="outlined"
                          sx={{
                            width: "100% !important",
                            borderColor: "#c4c4c4",
                            p: "10px",
                            borderRadius: "5px",
                            fontSize: "14px",
                          }}
                          placeholder="Address"
                          value={data?.address}
                          onChange={(e) =>
                            onChangeInput(e.target.value, "address")
                          }
                          minRows={2}
                        />
                      </div>
                    </div>
                  </Grid>
                  {(data?.role === "DELIVERY_BOY" ||
                    data?.role === "EXPRESS_DELIVERY_BOY" ||
                    data?.role === "TRANSPORT") && (
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                      <div className="sep_inpt_sec">
                        <p>Vehicle</p>

                        <div className="inp_sep_div">
                          <Select
                            name="key1"
                            labelId="country-lbl"
                            label=""
                            id="country"
                            value={data?.vehicle_id || ""}
                            style={{ width: "100%" }}
                            onChange={(e) => {
                              const ind = vehicle.findIndex(
                                (x) => x.number === e.target.value
                              );
                              setData({
                                ...data,
                                vehicle_id: e.target.value,
                                vehicle_number:
                                  ind > -1 ? vehicle[ind]?.number : "",
                              });
                            }}
                          >
                            {vehicle &&
                              vehicle.map((c) => {
                                return (
                                  <MenuItem key={c._id} value={c._id}>
                                    {c.number}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </div>
                      </div>
                    </Grid>
                  )}
                </Grid>
                {data?.role === "WAREHOUSE" && (
                  <div className="row">
                    <h4 className="label-head">PERMISSIONS</h4>
                    <hr />
                    <Grid container spacing={2} className="merchant_main_grid">
                      <Grid
                        item
                        xs={4}
                        sm={4}
                        md={2}
                        lg={2}
                        xl={2}
                        className="merchant_grid_sec"
                      >
                        <label>Dashboard </label>
                        <Switch
                          checked={warehouseMenu.Dashboard || false}
                          onChange={(e) => {
                            warehouseMenu.Dashboard = e.target.checked;
                            setWarehouseMenu({ ...warehouseMenu });
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sm={4}
                        md={2}
                        lg={2}
                        xl={2}
                        className="merchant_grid_sec"
                      >
                        <label>Receiver </label>
                        <Switch
                          checked={warehouseMenu.Receiver || false}
                          onChange={(e) => {
                            warehouseMenu.Receiver = e.target.checked;
                            setWarehouseMenu({ ...warehouseMenu });
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sm={4}
                        md={2}
                        lg={2}
                        xl={2}
                        className="merchant_grid_sec"
                      >
                        <label>Deliver </label>
                        <Switch
                          checked={warehouseMenu.Deliver || false}
                          onChange={(e) => {
                            warehouseMenu.Deliver = e.target.checked;
                            setWarehouseMenu({ ...warehouseMenu });
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sm={4}
                        md={2}
                        lg={2}
                        xl={2}
                        className="merchant_grid_sec"
                      >
                        <label>Express </label>
                        <Switch
                          checked={warehouseMenu.Express || false}
                          onChange={(e) => {
                            warehouseMenu.Express = e.target.checked;
                            setWarehouseMenu({ ...warehouseMenu });
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sm={4}
                        md={2}
                        lg={2}
                        xl={2}
                        className="merchant_grid_sec"
                      >
                        <label>Rider </label>
                        <Switch
                          checked={warehouseMenu.Rider || false}
                          onChange={(e) => {
                            warehouseMenu.Rider = e.target.checked;
                            setWarehouseMenu({ ...warehouseMenu });
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sm={4}
                        md={2}
                        lg={2}
                        xl={2}
                        className="merchant_grid_sec"
                      >
                        <label>Offline Customer </label>
                        <Switch
                          checked={warehouseMenu.Offline_customer || false}
                          onChange={(e) => {
                            warehouseMenu.Offline_customer = e.target.checked;
                            setWarehouseMenu({ ...warehouseMenu });
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sm={4}
                        md={2}
                        lg={2}
                        xl={2}
                        className="merchant_grid_sec"
                      >
                        <label>Detect Order </label>
                        <Switch
                          checked={warehouseMenu.Detect_order || false}
                          onChange={(e) => {
                            warehouseMenu.Detect_order = e.target.checked;
                            setWarehouseMenu({ ...warehouseMenu });
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sm={4}
                        md={2}
                        lg={2}
                        xl={2}
                        className="merchant_grid_sec"
                      >
                        <label>Location </label>
                        <Switch
                          checked={warehouseMenu.Location || false}
                          onChange={(e) => {
                            warehouseMenu.Location = e.target.checked;
                            setWarehouseMenu({ ...warehouseMenu });
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sm={4}
                        md={2}
                        lg={2}
                        xl={2}
                        className="merchant_grid_sec"
                      >
                        <label>Order </label>
                        <Switch
                          checked={warehouseMenu.Order || false}
                          onChange={(e) => {
                            warehouseMenu.Order = e.target.checked;
                            setWarehouseMenu({ ...warehouseMenu });
                          }}
                        />
                      </Grid>
                    </Grid>
                    <br />
                  </div>
                )}
                <h4 className="label-head">KYC DETAIL</h4>
                <hr />
                <Grid container spacing={2} className="merchant_main_grid">
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="merchant_grid_sec"
                  >
                    <div className="sep_inpt_sec">
                      <p>Aadhar Card</p>

                      <div className="inp_sep_div">
                        <CssTextField
                          id="new-menu"
                          className="create_merchant_input_feild"
                          variant="outlined"
                          placeholder="aadhar"
                          type="file"
                          fullWidth
                          inputProps={{
                            maxLength: 50,
                          }}
                          required
                          onChange={(e) => {
                            setAadharFile(e.target.files[0]);
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="merchant_grid_sec"
                  >
                    <div className="sep_inpt_sec">
                      <p>Aadhar Number</p>

                      <div className="inp_sep_div">
                        <CssTextField
                          id="new-menu"
                          className="create_merchant_input_feild"
                          variant="outlined"
                          placeholder="Aadhar Number"
                          type="number"
                          fullWidth
                          inputProps={{
                            maxLength: 50,
                          }}
                          value={data?.documents?.aadhar_number}
                          required
                          onChange={(e) => {
                            setData({
                              ...data,
                              documents: {
                                ...data.documents,
                                aadhar_number: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="merchant_grid_sec"
                  >
                    <div className="sep_inpt_sec">
                      <p>PAN Card</p>

                      <div className="inp_sep_div">
                        <CssTextField
                          id="new-menu"
                          className="create_merchant_input_feild"
                          variant="outlined"
                          placeholder="aadhar"
                          type="file"
                          fullWidth
                          inputProps={{
                            maxLength: 50,
                          }}
                          required
                          onChange={(e) => {
                            setPanFile(e.target.files[0]);
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="merchant_grid_sec"
                  >
                    <div className="sep_inpt_sec">
                      <p>PAN Number</p>

                      <div className="inp_sep_div">
                        <CssTextField
                          id="new-menu"
                          className="create_merchant_input_feild"
                          variant="outlined"
                          placeholder="PAN Number"
                          fullWidth
                          inputProps={{
                            maxLength: 50,
                          }}
                          value={data?.documents?.pan_number}
                          required
                          onChange={(e) => {
                            setData({
                              ...data,
                              documents: {
                                ...data.documents,
                                pan_number: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="merchant_grid_sec"
                  >
                    <div className="sep_inpt_sec">
                      <p>License</p>

                      <div className="inp_sep_div">
                        <CssTextField
                          id="new-menu"
                          className="create_merchant_input_feild"
                          variant="outlined"
                          placeholder="aadhar"
                          type="file"
                          fullWidth
                          inputProps={{
                            maxLength: 50,
                          }}
                          required
                          onChange={(e) => {
                            setLicenseFile(e.target.files[0]);
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="merchant_grid_sec"
                  >
                    <div className="sep_inpt_sec">
                      <p>License Number</p>

                      <div className="inp_sep_div">
                        <CssTextField
                          id="new-menu"
                          className="create_merchant_input_feild"
                          variant="outlined"
                          placeholder="License Number"
                          fullWidth
                          inputProps={{
                            maxLength: 50,
                          }}
                          value={data?.documents?.license_number}
                          required
                          onChange={(e) => {
                            setData({
                              ...data,
                              documents: {
                                ...data.documents,
                                license_number: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <br />
                <h4 className="label-head">BANK DETAIL</h4>
                <hr />
                <Grid container spacing={2} className="merchant_main_grid">
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="merchant_grid_sec"
                  >
                    <div className="sep_inpt_sec">
                      <p>Bank Name</p>

                      <div className="inp_sep_div">
                        <CssTextField
                          id="new-menu"
                          className="create_merchant_input_feild"
                          variant="outlined"
                          placeholder="Bank Name"
                          fullWidth
                          inputProps={{
                            maxLength: 50,
                          }}
                          value={data?.bank_detail?.bank_name}
                          required
                          onChange={(e) => {
                            setData({
                              ...data,
                              bank_detail: {
                                ...data.bank_detail,
                                bank_name: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="merchant_grid_sec"
                  >
                    <div className="sep_inpt_sec">
                      <p>Account Number</p>

                      <div className="inp_sep_div">
                        <CssTextField
                          id="new-menu"
                          className="create_merchant_input_feild"
                          variant="outlined"
                          placeholder="Account Number"
                          type="number"
                          fullWidth
                          inputProps={{
                            maxLength: 50,
                          }}
                          value={data?.bank_detail?.account_number}
                          required
                          onChange={(e) => {
                            setData({
                              ...data,
                              bank_detail: {
                                ...data.bank_detail,
                                account_number: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="merchant_grid_sec"
                  >
                    <div className="sep_inpt_sec">
                      <p>Holder Name</p>

                      <div className="inp_sep_div">
                        <CssTextField
                          id="new-menu"
                          className="create_merchant_input_feild"
                          variant="outlined"
                          placeholder="Holder Name"
                          fullWidth
                          inputProps={{
                            maxLength: 50,
                          }}
                          value={data?.bank_detail?.holder_name}
                          required
                          onChange={(e) => {
                            setData({
                              ...data,
                              bank_detail: {
                                ...data.bank_detail,
                                holder_name: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="merchant_grid_sec"
                  >
                    <div className="sep_inpt_sec">
                      <p>IFSC Code</p>

                      <div className="inp_sep_div">
                        <CssTextField
                          id="new-menu"
                          className="create_merchant_input_feild"
                          variant="outlined"
                          placeholder="ABCD01234"
                          fullWidth
                          inputProps={{
                            maxLength: 50,
                          }}
                          value={data?.bank_detail?.ifsc}
                          required
                          onChange={(e) => {
                            setData({
                              ...data,
                              bank_detail: {
                                ...data.bank_detail,
                                ifsc: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <br />
                <hr />
              </>
            )}

            {!enablemerchant && !loading ? (
              <div className="merchant_edit_button_dev">
                <Link to={`/user/list`}>
                  <Button
                    className="merchant_cancel_btn merchant_detail_tab_save-cancel_btn"
                    variant="contained"
                  >
                    Cancel
                  </Button>
                </Link>
                <Button
                  style={{ marginLeft: "15px" }}
                  className="merchant_save_btn merchant_detail_tab_save-cancel_btn"
                  variant="contained"
                  onClick={save}
                >
                  Update
                </Button>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div>
          <BootstrapDialog
            className="google-address-dialog"
            onClose={closeGoogleMap}
            aria-labelledby="customized-dialog-title"
            open={showMap}
          >
            <DialogContent>
              {/* <GoogleAddress
                onSubmitAddress={onSubmitAddress}
                center={center}
                setCenter={setCenter}
              /> */}
            </DialogContent>
          </BootstrapDialog>
        </div>
      </div>
    </>
  );
}

export default UserForm;
