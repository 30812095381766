import Colors from "../../constants/colors";

const EyeOpen = ({ color = Colors.black, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31.5"
      height="21.234"
      viewBox="0 0 31.5 21.234"
      fill={color === Colors.black ? Colors.black : color}
      {...props}
    >
      <path
        id="Icon_ionic-md-eye"
        data-name="Icon ionic-md-eye"
        d="M18,7.383A16.936,16.936,0,0,0,2.25,18a16.991,16.991,0,0,0,31.5,0A16.936,16.936,0,0,0,18,7.383Zm0,17.7A7.081,7.081,0,1,1,25.158,18,7.139,7.139,0,0,1,18,25.08Zm0-11.327A4.247,4.247,0,1,0,22.3,18,4.285,4.285,0,0,0,18,13.753Z"
        transform="translate(-2.25 -7.383)"
      />
    </svg>
  );
};

export default EyeOpen;
