import { useState, useEffect, useContext } from "react";
import "./styles.scss";
import ToastContext from "../../components/toast/toastContext";
import Loader from "../../components/loader";
import Confirmation from "../../components/confirmation";
import { Col, CardBody } from "reactstrap";
import order from "../../apis/order";
import user from "../../apis/user";
import TextField from "@mui/material/TextField";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import NoDataImg from "../../assets/images/no-data.jpg";
import Order from "../order";
import AccessDenied from "../../assets/images/access-denied.jpg";

const AllOrder = (props) => {
  const { toastMessage } = useContext(ToastContext);
  const [spinner, setSpinner] = useState(false);
  const [orders, setOrders] = useState([]);
  const [rider, setRider] = useState([]);
  const [showModal, setShowModel] = useState({
    show: false, // initial values set to false and null
    id: null,
    title: "Order Confirm",
    message: "",
  });

  const [open, setOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [search, setSearch] = useState("");

  const getAll = async () => {
    try {
      setSpinner(true);
      let query = `?sortBy=drop_date&sortOrder=asc`;
      const response = await order.getAllOrder(query);
      setOrders(response?.data?.data || []);
      setSpinner(false);
    } catch (err) {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getAll();
  }, []);

  const reloadData = () => {
    getAll();
  };

  const getRiderData = async () => {
    try {
      setSpinner(true);
      const response1 = await user.getAllRider();
      setRider(response1?.data?.data);
      setSpinner(false);
    } catch (err) {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getRiderData();
  }, []);

  const quickAssign = async () => {
    try {
      setSpinner(true);
      // let arr = [];
      // waitingOrder
      //   .filter((y) => y?.checked)
      //   .map((x) => {
      //     arr.push(x?._id);
      //   });
      // await order.confirmOrder(arr);
      setSpinner(false);
      toastMessage("success", "All Orders Confirmed successfully!");
      reloadData();
    } catch (err) {
      setSpinner(false);
    }
  };

  const hideConfirmationModal = () => {
    setShowModel({
      show: false,
      id: null,
      title: "",
      message: "",
    });
  };

  const submitConfirmed = () => {
    setShowModel({
      show: false,
      id: null,
      title: "",
      message: "",
    });
    quickAssign();
  };

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      if (!e.target.value) return;
      getAll();
    }
  };

  if (!props?.is_access) {
    return (
      <>
        <div className="no-access-section">
          <div style={{ height: "25vh" }}></div>
          <img src={AccessDenied} alt="" style={{ width: "20%" }} />
          <br />
          <label className="gr">
            You currently does not have access to this page
          </label>
          <br />
          <label className="gr">Please try again latter</label>
        </div>
      </>
    );
  }

  return (
    <>
      {spinner && (
        <div>
          <Loader loading={spinner} />
        </div>
      )}
      <div className="row profile-section">
        <div className="right-head-section">
          <h6 className="page-heading">
            Orders List{" "}
            <RotateLeftIcon
              className="reload-data-icon"
              onClick={reloadData}
              style={{ color: "#00ab92" }}
            />
          </h6>
          <div className="transporter-list">
            <TextField
              type="search"
              id="outlined-basic"
              variant="outlined"
              fullWidth
              size="small"
              value={search}
              label="Search order..."
              style={{ background: "#F7F7F7", borderColor: "#F7F7F7" }}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              onKeyDown={keyPress}
            />
          </div>
        </div>
        <Col md={12} className="order-outer-section" style={{ padding: 0 }}>
          {orders &&
            orders?.length > 0 &&
            orders
              ?.filter(
                (item) =>
                  (item?.tag_no &&
                    item?.tag_no.toLowerCase().includes(search)) ||
                  item?.order_id.toLowerCase().includes(search) ||
                  item?.customer?.name.toLowerCase().includes(search) ||
                  item?.customer?.mobile_no.toString().includes(search)
              )
              .map((item) => {
                return (
                  <div style={{ background: "white", borderRadius: "8px" }}>
                    <Order
                      item={item}
                      reloadData={reloadData}
                      open={open}
                      setOpen={setOpen}
                      selectedOrder={selectedOrder}
                      setSelectedOrder={setSelectedOrder}
                      key={item?._id}
                      rider={rider}
                    />
                  </div>
                );
              })}
          {(!orders || orders?.length === 0) && (
            <div
              className="no-data"
              style={{ width: "100%", background: "white" }}
            >
              <img src={NoDataImg} alt="no-data" style={{ height: "50vh" }} />
              <br />
              <label style={{ color: "lightgray" }}>No Data Found</label>
            </div>
          )}
        </Col>
      </div>

      <Confirmation
        data={showModal}
        confirmModal={submitConfirmed}
        hideModal={hideConfirmationModal}
      />
    </>
  );
};

export default AllOrder;
