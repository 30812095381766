import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import "./index.scss";
import {
  GoogleMap,
  useLoadScript,
  MarkerF,
  CircleF,
} from "@react-google-maps/api";
import api from "../../apis/api";
import Loader from "../../components/loader";
import AccessDenied from "../../assets/images/access-denied.jpg";

const libraries = ["places"];
const mapContainerStyle = {
  width: "100%",
  height: "75vh",
};

// const circleOptions = {
//   strokeColor: "#FF0000",
//   strokeOpacity: 0.8,
//   strokeWeight: 2,
//   fillColor: "#FF0000",
//   fillOpacity: 0.35,
//   radius: 10000, // 10km in meters
// };

const Location = (props) => {
  // const { onSubmitAddress, center, setCenter } = props;
  const [circleOptions, setCircleOptions] = useState({
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
    radius: 10000, // 10km in meters
  });
  const [loading, setLoading] = useState(false);
  const [rider, setRider] = useState([]);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries,
  });

  const [center, setCenter] = useState({
    lat: 37.7749,
    lng: -122.4194,
  });

  const getUserLocation = () => {
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
  };

  useEffect(() => {
    // getUserLocation();
  }, []);

  const getWarehouseDetail = async () => {
    api.get(`/warehouse-detail`).then((response) => {
      setCenter({
        lat: response?.data?.warehouseData?.lat,
        lng: response?.data?.warehouseData?.lng,
      });
      setCircleOptions({
        ...circleOptions,
        radius: Number(response?.data?.WAREHOUSE_DISTANCE || 5) * 1000,
      });
      setRider(
        response?.data?.riderData.map((x) => {
          return {
            label: x?.name,
            lat: x?.lat,
            lng: x?.lng,
          };
        }) || []
      );
    });
  };

  useEffect(() => {
    getWarehouseDetail();
  }, []);

  if (loading) {
    return (
      <div className="no_data_found loader_data_tbl">
        <CircularProgress />
      </div>
    );
  }

  if (!props?.is_access) {
    return (
      <>
        <div className="no-access-section">
          <div style={{ height: "25vh" }}></div>
          <img
            src={AccessDenied}
            alt=""
            style={{ width: "20%" }}
          />
          <br />
          <label className="gr">
            You currently does not have access to this page
          </label>
          <br />
          <label className="gr">Please try again latter</label>
        </div>
      </>
    );
  }

  return (
    <>
      {!isLoaded && (
        <div>
          <Loader loading={!isLoaded} />
        </div>
      )}

      <div className="row profile-section">
        <div className="right-head-section">
          <h6 className="page-heading">Warehouse Map</h6>
          <div className="transporter-list"></div>
        </div>
        <div className="row customer-outer-section mt-2">
          {isLoaded && (
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              zoom={12}
              center={center}
              // onClick={handleMapClick}
            >
              <MarkerF
                position={center}
                icon={{
                  url: "./cloth-map.png",
                  scaledSize: new window.google.maps.Size(40, 40),
                  origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(20, 40),
                }}
                search={true}
                draggable={true}
                onDragEnd={(event) => {
                  setCenter({
                    lat: event.latLng.lat(),
                    lng: event.latLng.lng(),
                  });
                }}
                title="Drag to change location"
                animation={window.google.maps.Animation.DROP}
              />
              {rider.map((r) => {
                return (
                  <MarkerF
                    position={r}
                    icon={{
                      url: "./scooter_icon.png",
                      scaledSize: new window.google.maps.Size(40, 40),
                      origin: new window.google.maps.Point(0, 0),
                      anchor: new window.google.maps.Point(20, 40),
                    }}
                    title={r?.label}
                    animation={window.google.maps.Animation.DROP}
                  />
                );
              })}
              <CircleF center={center} options={circleOptions} />
            </GoogleMap>
          )}
        </div>
      </div>
    </>
  );
};

export default Location;
