import api from "../api";
import commonEndpoint from "./config";

const common = {
  async fileUpload(file) {
    let formData = new FormData();
    formData.append("image", file);
    return await api.post(commonEndpoint.fileUpload, formData);
  },

  async getDashboardData() {
    return await api.get(commonEndpoint.dashboardUrl);
  },

  async getOfflineCustomerData() {
    return await api.get(commonEndpoint.offlineCustomerUrl);
  },

  async getCustomers(search) {
    return await api.get(
      commonEndpoint.customerUrl + "?pageNo=0&rowsPerPage=25&search=" + search
    );
  },

  async getAddress(id) {
    return await api.get(commonEndpoint.addressUrl + "/" + id);
  },

  async getOrderDetail(id) {
    return await api.get(
      `/order/${id}/single-order`
    );
  }
};

export default common;
