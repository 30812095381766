import Text from "../../text";
import ErrorIcon from "../../../assets/icons/error";
import SuccessIcon from "../../../assets/icons/success";
import ExclamationIcon from "../../../assets/icons/exclamation";
import CrossIcon from "../../../assets/icons/cross";
import Colors from "../../../constants/colors";
import "../../../constants/styles.scss";
import "./styles.scss";

const Item = ({ type = "success", message, id, onClickCross }) => {
  const getStyles = () => {
    switch (type) {
      case "success":
        return "toast-box-main-container toast-box-success-style";
      case "error":
        return "toast-box-main-container toast-box-error-style";
      case "warning":
        return "toast-box-main-container toast-box-warning-style";
      default:
        break;
    }
  };

  return (
    <div className={getStyles()}>
      <div className="toast-box-left-side-container">
        {(type === "success" && <SuccessIcon color={Colors.shamrock} />) ||
          (type === "error" && <ErrorIcon color={Colors.coral} />) ||
          (type === "warning" && <ExclamationIcon color={Colors.saffron} />)}

        <Text
          className={`toast-box-status-text-style fs-12 fw-b ${
            (type === "success" && "fc-shamrock") ||
            (type === "error" && "fc-coral") ||
            (type === "warning" && "fc-saffron")
          }`}
          value={
            (type === "success" && "Success!") ||
            (type === "error" && "Oops!") ||
            (type === "warning" && "Warning!")
          }
        />
        <Text className="fc-nero fs-12" value={message} />
      </div>

      <div className="toast-box-right-side-container">
        <button className="toast-box-menu-button-style" onClick={onClickCross}>
          <CrossIcon
            color={
              (type === "success" && Colors.shamrock) ||
              (type === "error" && Colors.coral) ||
              (type === "warning" && Colors.saffron)
            }
          />
        </button>
      </div>
    </div>
  );
};

export default Item;
