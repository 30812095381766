import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showLoading: false,
};

export const UiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setLoader: (state, action) => {
      state.showLoading = action.payload;
    },
  },
});

export const { setLoader } = UiSlice.actions;
export default UiSlice.reducer;
