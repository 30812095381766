import Text from "../text";
import Exclamation from "../../assets/icons/exclamation";
import Colors from "../../constants/colors";

import "../../constants/styles.scss";
import "./styles.scss";

const Input = ({
  label,
  placeholder,
  value,
  onChange,
  type = "text",
  rightSideIcon,
  onClickRightSideIcon,
  errorText,
  className,
  disabled = false,
}) => {
  return (
    <div className={className + " input-main-container-style"}>
      <Text value={label} className="fs-14 mb-2 fc-dimGrey" />
      <div className="input-container-style">
        <input
          value={value}
          onChange={onChange}
          className="input-style"
          type={type}
          placeholder={placeholder}
          style={{ paddingRight: rightSideIcon && "39px" }}
          disabled={disabled}
        />
        {rightSideIcon && (
          <button
            className="input-icon-container-style"
            onClick={onClickRightSideIcon}
          >
            {rightSideIcon}
          </button>
        )}
      </div>
      {errorText && (
        <div className="input-error-container-style">
          <Exclamation color={Colors.scarletRed} />
          <Text className="fs-12 ms-1 fc-scarletRed" value={errorText} />
        </div>
      )}
    </div>
  );
};

export default Input;
