import { useState, useEffect, useContext } from "react";
import "./styles.scss";
import Loader from "../../components/loader";
import { Col, CardBody, Card, CardHeader } from "reactstrap";
import order from "../../apis/order";
import Badge from "@mui/material/Badge";
import TextField from "@mui/material/TextField";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import NoDataImg from "../../assets/images/no-data.jpg";
import Order from "../order";
import BarcodeReader from "react-barcode-reader";
import Barcode from "react-barcode";
import ToastContext from "../../components/toast/toastContext";
import AccessDenied from "../../assets/images/access-denied.jpg";

let options = {
  width: 1.2,
  height: 30,
  format: "CODE128",
  displayValue: true,
  // font: "monospace",
  // fontOptions: "bold",
  fontSize: 21,
  textAlign: "center",
  textPosition: "bottom",
  textMargin: 4,
  background: "#ffffff",
  lineColor: "#000000",
  margin: 10,
};

const DetectOrder = (props) => {
  const { toastMessage } = useContext(ToastContext);

  const [spinner, setSpinner] = useState(false);
  const [allOrders, setAllOrders] = useState([]);

  const [selectedOrders, setSelectedOrders] = useState([]);
  const [selectedTag, setSelectedTag] = useState("");

  const getALl = async () => {
    try {
      setSpinner(true);
      let query = `?sortBy=drop_date&sortOrder=asc`;
      const response = await order.getAllOrder(query);
      let arr1 = [];
      response?.data?.data.map((x) => {
        let arr = [];
        x.services.map((y) => {
          if (y?.barcode && y.barcode.length) {
            y.barcode.map((z) => {
              arr.push(z);
            });
          }
        });
        if (arr.length) {
          arr1.push({
            tag_no: x.tag_no,
            quantity: x?.quantity,
            barcode: arr,
          });
        }
      });
      setAllOrders(arr1);
      setSpinner(false);
    } catch (err) {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getALl();
  }, []);

  const reloadData = () => {
    setSelectedOrders([]);
    getALl();
  };

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      if (!e.target.value) return;
      handleScan(e.target.value);
      setSelectedTag("");
    }
  };

  const handleScan = (data) => {
    const barcode = data?.toString();
    let arr = JSON.parse(JSON.stringify(selectedOrders));
    let ind = -1;
    let tag_no = "";
    let totalQty = 0;
    for (var i = 0; i < allOrders.length; i++) {
      ind = allOrders[i].barcode.findIndex((y) => y == barcode);
      if (ind != -1) {
        tag_no = allOrders[i].tag_no;
        totalQty = allOrders[i].quantity;
        break;
      }
    }
    if (ind > -1) {
      let ind1 = arr.findIndex((x) => x.tag_no == tag_no);
      if (ind1 == -1) {
        arr.push({
          tag_no: tag_no,
          totalQty,
          barcode: [barcode],
        });
      } else {
        let ind2 = arr[ind1].barcode.findIndex((x) => x == barcode);
        if (ind2 == -1) {
          arr[ind1].barcode.push(barcode);
        } else {
          // already exist
          toastMessage("error", "Already exist Bar-Code!");
          return;
        }
      }
    } else {
      // invalid barcode
      toastMessage("error", "Invalid Bar-Code!");
      return;
    }
    toastMessage("success", "Success!");
    setSelectedOrders(
      arr.map((x) => {
        return {
          ...x,
          barcode: x.barcode.sort((a, b) => {
            if (a < b) {
              return -1;
            } else {
              return 1;
            }
          }),
        };
      })
    );
  };

  const handleError = (err) => {
    console.error(err);
  };

  if (!props?.is_access) {
    return (
      <>
        <div className="no-access-section">
          <div style={{ height: "25vh" }}></div>
          <img
            src={AccessDenied}
            alt=""
            style={{ width: "20%" }}
          />
          <br />
          <label className="gr">
            You currently does not have access to this page
          </label>
          <br />
          <label className="gr">Please try again latter</label>
        </div>
      </>
    );
  }
  
  return (
    <>
      <BarcodeReader onError={handleError} onScan={handleScan} />
      {spinner && (
        <div>
          <Loader loading={spinner} />
        </div>
      )}

      <div className="row profile-section">
        <div className="right-head-section">
          <h6 className="page-heading">
            Detect Order By BarCode{" "}
            <RotateLeftIcon
              className="reload-data-icon"
              onClick={reloadData}
              style={{ color: "#00ab92" }}
            />
          </h6>
          <div className="transporter-list">
            <TextField
              type="search"
              id="outlined-basic"
              variant="outlined"
              fullWidth
              size="small"
              value={selectedTag}
              label="Enter barcode"
              style={{ background: "#e2e5ea" }}
              onChange={(e) => {
                setSelectedTag(e.target.value);
              }}
              onKeyDown={keyPress}
            />
          </div>
        </div>
        {selectedOrders.map((item, index) => {
          return (
            <Col
              md={2}
              className="detect-order-outer-section"
              style={{ paddingLeft: 0 }}
              key={index}
            >
              <Card>
                <Badge badgeContent={`${item?.barcode.length}/${item?.totalQty}`} color="secondary"></Badge>
                <CardHeader>
                  <b>{item?.tag_no}</b>
                </CardHeader>
                <CardBody
                  className="barcode-body"
                  style={{ textAlign: "center" }}
                >
                  {item?.barcode?.map((b, index1) => {
                    return (
                      <span className="" key={index1}>
                        <Barcode {...options} value={b} />
                      </span>
                    );
                  })}
                </CardBody>
              </Card>
            </Col>
          );
        })}
      </div>
    </>
  );
};

export default DetectOrder;
