import { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import loginPageClipart from "../../assets/images/login-clipart.png";
import EyeOpen from "../../assets/icons/eyeOpen";
import EyeClose from "../../assets/icons/eyeClose";

import Text from "../../components/text";
import Input from "../../components/input";
import Button from "../../components/button";
import ToastContext from "../../components/toast/toastContext";

import { loginAddedUsers } from "../../store/reducer/auth";
import { setLoader } from "../../store/reducer/ui";
import { verifyEmail, verifyPassword, isLoggedIn } from "../../utils/helper";

import "./styles.scss";
import "../../constants/styles.scss";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { toastMessage } = useContext(ToastContext);

  const [showHidePassword, setShowHidePassword] = useState("hide");
  const [emailValue, setEmailValue] = useState("");
  const [emailErrorText, setEmailErrorText] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [passwordErrorText, setPasswordErrorText] = useState("");

  useEffect(() => {
    isLoggedIn() && navigate("/");
  });

  const onChangeInput = (event, type) => {
    const value = event.target.value;
    switch (type) {
      case "email_id":
        setEmailValue(value);
        verifyEmail(value)
          ? setEmailErrorText("")
          : setEmailErrorText("Enter valid email_id address.");
        break;
      case "password":
        setPasswordValue(value);
        verifyPassword(value)
          ? setPasswordErrorText("")
          : setPasswordErrorText("Password must be at least 8 characters.");
        break;
      default:
        break;
    }
  };

  const onClickShowHidePassword = () => {
    switch (showHidePassword) {
      case "hide":
        setShowHidePassword("show");
        break;
      case "show":
        setShowHidePassword("hide");
        break;
      default:
        break;
    }
  };

  const onClickLogin = async () => {
    dispatch(setLoader(true));
    const data = {
      email_id: emailValue,
      password: passwordValue,
      role: "WAREHOUSE",
    };
    const { error, payload } = await dispatch(loginAddedUsers(data));
    if (error) {
      toastMessage(
        "error",
        "Incorrect Id or Password, or user login on other device"
      );
    } else if (payload.status === 200 && payload.data.status) {
      toastMessage("success", "Successfully Logged");
      navigate("/");
    } else {
      toastMessage(
        "error",
        "Incorrect Email Id or Password!"
      );
    }
    dispatch(setLoader(false));
  };

  const forgot = () => {
    navigate("/forgot");
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-5 display-flex-column-align-center-justify-center">
          <Text
            className="fw-b fs-22 mb-2 text-center-align"
            value={
              <>
                A champion is afraid of losing.<br></br>Everyone else is afraid
                of winning.
              </>
            }
          />
          <Text
            className="fs-16 mb-4 text-center-align"
            value={
              <>
                At ClothiQ, we take pride in delivering a seamless garment care
                experience. Our warehouse operations are the heart of this
                process, ensuring precision and efficiency every step of the
                way.
              </>
            }
          />
          <img src={loginPageClipart} alt="profile-img" width="70%" />
        </div>
        <div
          className="col-7 login-page-right-side-style 
        display-flex-column-align-center-justify-center"
        >
          <Text
            className="fs-24 fw-b mb-5 text-center-align"
            value="ClothiQ Warehouse Operations Portal!"
          />
          <Input
            className="login-page-fields"
            label="Email Address"
            placeholder="Enter Your Email Address"
            value={emailValue}
            onChange={(event) => {
              onChangeInput(event, "email_id");
            }}
            errorText={emailErrorText}
          />
          <Input
            className="login-page-fields"
            label="Password"
            placeholder="Enter Your Password"
            value={passwordValue}
            onChange={(event) => {
              onChangeInput(event, "password");
            }}
            type={
              (showHidePassword === "show" && "text") ||
              (showHidePassword === "hide" && "password")
            }
            errorText={passwordErrorText}
            rightSideIcon={
              (showHidePassword === "show" && (
                <EyeOpen className="login-page-input-right-side-icon-style" />
              )) ||
              (showHidePassword === "hide" && (
                <EyeClose className="login-page-input-right-side-icon-style" />
              ))
            }
            onClickRightSideIcon={onClickShowHidePassword}
          />
          <Button
            className="mb-4"
            text="Login"
            onClick={onClickLogin}
            disabled={
              verifyEmail(emailValue) && verifyPassword(passwordValue)
                ? false
                : true
            }
          />
          <Button
            type="link"
            onClick={() => forgot()}
            text="Forgot Password?"
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
