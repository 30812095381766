import "./styles.scss";
import { useState, useEffect, useContext } from "react";
import { Row, Col, Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  MenuItem,
  Select,
  Grid,
  TextField,
  styled,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Filter from "../../../components/filter";
import api from "../../../apis/api";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import serachIcon from "../../../assets/icons/search.svg";
import SecondSection from "./second-section";
// import OrderDetails from "../../../components/orderDetail";
// import OrderCountChart from "../countChart";

const CssTextField = styled(TextField)({
  "input#new-menu": {
    width: "520px",
    height: "2px",
    backgroundColor: "#FEFEFE",
  },
});

const allStatus = [
  {
    value: "INITIATE",
    label: "Initiate",
  },
  {
    value: "CANCELLED",
    label: "Cancelled",
  },
  {
    value: "RECEIVED_BY_RIDER_FROM_CUSTOMER",
    label: "Received By Rider From Customer",
  },
  {
    value: "WAITING_RECEIVED_BY_TRANSPORTER_FROM_RIDER",
    label: "Waiting Received By Rider From Customer",
  },
  {
    value: "RECEIVED_BY_TRANSPORTER_FROM_RIDER",
    label: "Received By Transporter From Rider",
  },
  {
    value: "WAITING_RECEIVED_BY_WAREHOUSE",
    label: "Waiting Received By Warehouse",
  },
  {
    value: "RECEIVED_BY_WAREHOUSE",
    label: "Received By Warehouse",
  },
  {
    value: "READY_FOR_SERVICE",
    label: "Ready For Service",
  },
  {
    value: "READY_FOR_RETURN",
    label: "Ready For Return",
  },
  {
    value: "RECEIVED_BY_TRANSPORTER_FROM_WAREHOUSE",
    label: "Received By Transporter From Warehouse",
  },
  {
    value: "WAITING_RECEIVED_BY_RIDER_FROM_TRANSPORTER",
    label: "Waiting Received By Rider From Transporter",
  },
  {
    value: "RECEIVE_BY_RIDER_FROM_TRANSPORTER",
    label: "Received By Rider From Transporter",
  },
  {
    value: "COMPLETED",
    label: "Completed",
  },
];

const AllOrderBox = (props) => {
  const authData = useSelector((state) => state.auth);

  const [count, setCount] = useState(1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [chartData, setChartData] = useState([]);
  const [filter, setFilter] = useState({
    dateRange: "Today",
    from_date: moment().format("YYYY-MM-DD"),
    to_date: moment().format("YYYY-MM-DD"),
    currency: "INR",
    country: "IND",
  });
  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });

  const getData = async (dateRange, from_date, to_date, currency, country) => {
    setSpinner(true);
    let ft = [
      {
        key: "warehouse_id",
        value: authData?.user_data?.warehouse_id,
        type: "is",
      },
    ];
    if (selectedStatus && selectedStatus != "all") {
      ft.push({
        key: "status",
        value: selectedStatus,
        type: "is",
      });
    }
    const response = await api.get(
      `/order?pageNo=` +
        page +
        `&rowsPerPage=` +
        rowsPerPage +
        `&sortBy=_id&sortOrder=desc&dateRange=custom&dateFrom=${from_date}&dateTo=${to_date}&dateField=createdAt&search=` +
        search +
        `&filter=` +
        JSON.stringify(ft)
    );
    setData(response?.data?.data);
    setCount(response.data.count);
    setChartData(response?.data?.chartData || []);
    setSpinner(false);
  };

  useEffect(() => {
    getData(
      filter.dateRange,
      filter.from_date,
      filter.to_date,
      filter.currency,
      filter.country
    );
  }, [page, rowsPerPage, search, selectedStatus]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const getDateRange = (value) => {
    let from_date = moment().format("YYYY-MM-DD");
    let to_date = moment().format("YYYY-MM-DD");
    if (value == "Today") {
      from_date = moment().format("YYYY-MM-DD");
      to_date = moment().format("YYYY-MM-DD");
    } else if (value == "Yesterday") {
      from_date = moment().subtract(1, "day").format("YYYY-MM-DD");
      to_date = moment().subtract(1, "day").format("YYYY-MM-DD");
    } else if (value == "This Week") {
      from_date = moment().startOf("isoWeek").format("YYYY-MM-DD");
      to_date = moment().endOf("isoWeek").format("YYYY-MM-DD");
    } else if (value == "This Month") {
      from_date = moment().startOf("month").format("YYYY-MM-DD");
      to_date = moment().endOf("month").format("YYYY-MM-DD");
    } else if (value == "This Year") {
      from_date = moment().startOf("year").format("YYYY-MM-DD");
      to_date = moment().endOf("year").format("YYYY-MM-DD");
    } else if (value == "Custom") {
      from_date = moment().startOf("year").format("YYYY-MM-DD");
      to_date = moment().endOf("year").format("YYYY-MM-DD");
    }
    return { from_date, to_date };
  };

  const handleFilter = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setDate({
      startDate: "",
      endDate: "",
    });
    if (name === "dateRange") {
      if (value === "Custom") {
        setFilter({
          ...filter,
          dateRange: value,
          from_date: filter.from_date,
          to_date: filter.to_date,
        });
        return true;
      }
      const { from_date, to_date } = getDateRange(value);
      getData(value, from_date, to_date, filter.currency, filter.country);
      setFilter({
        ...filter,
        dateRange: value,
        from_date,
        to_date,
      });
      return true;
    }
    setFilter({
      ...filter,
      [name]: value,
    });
    return true;
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handledates = (values) => {
    const from_date = moment(values.startDate).format("YYYY-MM-DD");
    const to_date = moment(values.endDate).format("YYYY-MM-DD");
    getData(
      filter.dateRange,
      from_date,
      to_date,
      filter.currency,
      filter.country
    );
    setDate(values);
  };

  const handleClear = (value) => {
    if (value == "dateRange") {
      setFilter({
        ...filter,
        dateRange: "Today",
        from_date: moment().format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
      });
      const filterDate = moment().format("YYYY-MM-DD");
      getData("Today", filterDate, filterDate, filter.currency, filter.country);
      return true;
    }
  };

  // const list = (anchor) => (
  //   <OrderDetails
  //     anchor={anchor}
  //     data={state?.data}
  //     menu_permissions={props?.menu_permissions}
  //   />
  // );

  return (
    <div className="order-box-status">
      <div className="list_search_filter">
        <CssTextField
          className="list_search_bar"
          id="new-menu"
          placeholder="search"
          variant="outlined"
          fullWidth
          onKeyUp={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment>
                <IconButton className="search_icon">
                  <img src={serachIcon} alt="search" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Select
          value={selectedStatus}
          size="small"
          onChange={(e) => {
            setSelectedStatus(e.target.value);
          }}
          className="status-select-dropdown"
        >
          <MenuItem key="all" value="all">
            All Status
          </MenuItem>
          {allStatus.map((w) => {
            return (
              <MenuItem key={w.value} value={w.value}>
                {w.label}
              </MenuItem>
            );
          })}
        </Select>
        <Filter
          handleFilter={handleFilter}
          handleClear={handleClear}
          filter={filter}
          handledates={handledates}
        />
      </div>
      <h3 className="list_inner_title">Order List</h3>
      {spinner ? (
        <div className="no_data_found loader_data_tbl">
          <CircularProgress style={{ color: "#1058ff" }} />
        </div>
      ) : (
        <>
          <TableContainer className="table_container all-order-table">
            <Table stickyHeader aria-label="sticky table">
              <TableHead className="data_tbl_head_row">
                <TableRow style={{ background: "none" }}>
                  <TableCell>Order ID</TableCell>
                  <TableCell>Tag</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Address</TableCell>
                  {/* <TableCell>Quantity</TableCell> */}
                  <TableCell>Amount</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell style={{ minWidth: "100px" }}>
                    Created At
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="data_tbl_body_row">
                {data.map((row, index) => (
                  <TableRow key={index} style={{ background: "none" }}>
                    <TableCell>{row?.order_id}</TableCell>
                    <TableCell>{row.tag_no || "NA"}</TableCell>
                    <TableCell>{row.type}</TableCell>
                    <TableCell>{row.address || "NA"}</TableCell>
                    {/* <TableCell>{row.quantity}</TableCell> */}
                    <TableCell>
                      INR {Number(row.amount || 0).toFixed(2)}
                    </TableCell>
                    <TableCell>
                      <label
                        className={
                          row?.status == "COMPLETED"
                            ? "completed-status"
                            : row?.status == "CANCELLED"
                            ? "cancelled-status"
                            : ""
                        }
                      >
                        {row.status}
                      </label>
                    </TableCell>
                    <TableCell>
                      {moment(row.createdAt).format("DD MMM YYYY hh:mm A")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            className="table_pagination"
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          <SecondSection
            data={
              chartData && chartData.length ? chartData[0].order_detail : []
            }
            filter={filter}
          />
        </>
      )}
    </div>
  );
};

export default AllOrderBox;
