import "./styles.scss";

const Text = ({ value, className, ...props }) => {
  return (
    <div
      className={className ? `${className} text-main-style` : "text-main-style"}
      {...props}
    >
      {value}
    </div>
  );
};

export default Text;
