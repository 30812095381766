import Colors from "../../constants/colors";

const CrossIcon = ({ color = Colors.black, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      {...props}
    >
      <path
        id="noun-close-1180194"
        d="M114.786,35.214a.69.69,0,0,0-1,0L110,39l-3.786-3.786a.707.707,0,0,0-1,1L109,40l-3.786,3.786a.69.69,0,0,0,0,1,.69.69,0,0,0,1,0L110,41l3.786,3.786a.69.69,0,0,0,1,0,.69.69,0,0,0,0-1L111,40l3.786-3.786A.69.69,0,0,0,114.786,35.214Z"
        transform="translate(-105 -35)"
        fill={color === Colors.black ? Colors.black : color}
      />
    </svg>
  );
};

export default CrossIcon;
