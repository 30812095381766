import { useState, useEffect, useContext } from "react";
import "./styles.scss";
import ToastContext from "../../components/toast/toastContext";
import Loader from "../../components/loader";
import Confirmation from "../../components/confirmation";
import {
  Col,
  CardBody,
  CardFooter,
  Card,
  Button,
  CardHeader,
} from "reactstrap";
import order from "../../apis/order";
import Badge from "@mui/material/Badge";
import TextField from "@mui/material/TextField";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import NoDataImg from "../../assets/images/no-data.jpg";
import Order from "../order";
import AccessDenied from "../../assets/images/access-denied.jpg";

const Receiver = (props) => {
  const { toastMessage } = useContext(ToastContext);
  const [spinner, setSpinner] = useState(false);
  const [waitingOrder, setWaitingOrder] = useState([]);
  const [confirmOrder, setConfirmOrder] = useState([]);
  const [fastConfirmOrder, setFastConfirmOrder] = useState([]);
  const [showModal, setShowModel] = useState({
    show: false, // initial values set to false and null
    id: null,
    title: "Order Confirm",
    message: "",
  });

  const [open, setOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedTag, setSelectedTag] = useState("");

  const getWaitingData = async () => {
    try {
      setSpinner(true);
      let query = `?sortBy=drop_date&sortOrder=asc`;
      const response = await order.getWaitingOrder(query);
      const arr = response?.data?.data?.map((x) => {
        return {
          ...x,
          checked: true,
        };
      });
      setWaitingOrder(arr);
      setSpinner(false);
    } catch (err) {
      setSpinner(false);
    }
  };

  const getConfirmedData = async () => {
    try {
      setSpinner(true);
      let query = `?sortBy=drop_date&sortOrder=asc`;
      const response = await order.getConfirmedOrder(query);
      setConfirmOrder(response?.data?.data.filter((x) => x.type === "NORMAL"));
      setFastConfirmOrder(
        response?.data?.data.filter((x) => x.type === "FAST")
      );
      setSpinner(false);
    } catch (err) {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getWaitingData();
    getConfirmedData();
  }, []);

  const reloadData = () => {
    getWaitingData();
    getConfirmedData();
  };

  const acceptOrder = async () => {
    try {
      setSpinner(true);
      let arr = [];
      waitingOrder
        .filter((y) => y?.checked)
        .map((x) => {
          arr.push(x?._id);
        });
      await order.confirmOrder(arr);
      setSpinner(false);
      toastMessage("success", "All Orders Confirmed successfully!");
      reloadData();
    } catch (err) {
      setSpinner(false);
    }
  };

  const onConfirmOrder = async () => {
    setShowModel({
      show: true,
      id: null,
      title: "Order Confirm!",
      message: "Are you sure want to confirm these all orders ?",
    });
  };

  const hideConfirmationModal = () => {
    setShowModel({
      show: false,
      id: null,
      title: "",
      message: "",
    });
  };

  const submitConfirmed = () => {
    setShowModel({
      show: false,
      id: null,
      title: "",
      message: "",
    });
    acceptOrder();
  };

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      if (!e.target.value) return;
      const arr = [...waitingOrder, ...confirmOrder, ...fastConfirmOrder];
      const item = arr.find((x) => x.tag_no === e.target.value);
      if (!item) return;
      setSelectedOrder(item);
      setSelectedTag("");
      setOpen(true);
    }
  };

  const onChangeCheckboxValue = (val, id) => {
    let ind = waitingOrder.findIndex((x) => x._id.toString() == id.toString());
    if (ind > -1) {
      waitingOrder[ind].checked = val;
      setWaitingOrder([...waitingOrder]);
    }
  };

  if (!props?.is_access) {
    return (
      <>
        <div className="no-access-section">
          <div style={{ height: "25vh" }}></div>
          <img
            src={AccessDenied}
            alt=""
            style={{ width: "20%" }}
          />
          <br />
          <label className="gr">
            You currently does not have access to this page
          </label>
          <br />
          <label className="gr">Please try again latter</label>
        </div>
      </>
    );
  }

  return (
    <>
      {spinner && (
        <div>
          <Loader loading={spinner} />
        </div>
      )}
      <div className="row profile-section">
        <div className="right-head-section">
          <h6 className="page-heading">
            Receiver Details{" "}
            <RotateLeftIcon
              className="reload-data-icon"
              onClick={reloadData}
              style={{ color: "#00ab92" }}
            />
          </h6>
          <div className="transporter-list">
            <TextField
              type="search"
              id="outlined-basic"
              variant="outlined"
              fullWidth
              size="small"
              value={selectedTag}
              label="Search order..."
              style={{ background: "#F7F7F7", borderColor: "#F7F7F7" }}
              onChange={(e) => {
                setSelectedTag(e.target.value);
              }}
              onKeyDown={keyPress}
            />
          </div>
        </div>
        <Col md={6} className="order-outer-section" style={{ paddingLeft: 0 }}>
          <Card>
            <Badge
              badgeContent={waitingOrder?.length || 0}
              color="secondary"
            ></Badge>
            <CardHeader>
              <b>Waiting For Confirmation</b>
            </CardHeader>
            <CardBody>
              {waitingOrder &&
                waitingOrder?.length > 0 &&
                waitingOrder
                  ?.filter(
                    (item) =>
                      item?.tag_no.toLowerCase().includes(selectedTag) ||
                      item?.order_id.toLowerCase().includes(selectedTag) ||
                      item?.customer?.name.toLowerCase().includes(selectedTag) ||
                      item?.customer?.mobile_no.toString().includes(selectedTag)
                  )
                  .map((item) => {
                    return (
                      <Order
                        item={item}
                        reloadData={reloadData}
                        open={open}
                        setOpen={setOpen}
                        selectedOrder={selectedOrder}
                        setSelectedOrder={setSelectedOrder}
                        key={item?._id}
                        from="receiver"
                        type="requested"
                        onChangeCheckboxValue={onChangeCheckboxValue}
                      />
                    );
                  })}
              {(!waitingOrder || waitingOrder?.length === 0) && (
                <div className="no-data">
                  <img
                    src={NoDataImg}
                    alt="no-data"
                    style={{ height: "50vh" }}
                  />
                  <br />
                  <label style={{ color: "lightgray" }}>No Data Found</label>
                </div>
              )}
            </CardBody>
            {waitingOrder && waitingOrder?.length > 0 && (
              <CardFooter>
                <Button
                  className="confirm-btn"
                  onClick={() => {
                    onConfirmOrder();
                  }}
                >
                  Confirm
                </Button>
              </CardFooter>
            )}
          </Card>
        </Col>
        <Col md={6} className="order-outer-section" style={{ paddingRight: 0 }}>
          <Card>
            <Badge
              badgeContent={confirmOrder?.length || 0}
              color="secondary"
            ></Badge>
            <CardHeader>
              <b>Normal Confirmed Order</b>
            </CardHeader>
            <CardBody>
              {confirmOrder &&
                confirmOrder?.length > 0 &&
                confirmOrder
                  ?.filter(
                    (item) =>
                      item?.tag_no.toLowerCase().includes(selectedTag) ||
                      item?.order_id.toLowerCase().includes(selectedTag) ||
                      item?.customer?.name.toLowerCase().includes(selectedTag) ||
                      item?.customer?.mobile_no.toString().includes(selectedTag)
                  )
                  .map((item) => {
                    return (
                      <Order
                        item={item}
                        reloadData={reloadData}
                        open={open}
                        setOpen={setOpen}
                        selectedOrder={selectedOrder}
                        setSelectedOrder={setSelectedOrder}
                        key={item?._id}
                        from="receiver"
                      />
                    );
                  })}
              {(!confirmOrder || confirmOrder?.length === 0) && (
                <div className="no-data">
                  <img
                    src={NoDataImg}
                    alt="no-data"
                    style={{ height: "50vh" }}
                  />
                  <br />
                  <label style={{ color: "lightgray" }}>No Data Found</label>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
        <Col md={6} className="order-outer-section" style={{ paddingLeft: 0 }}>
          <Card>
            <Badge
              badgeContent={fastConfirmOrder?.length || 0}
              color="secondary"
            ></Badge>
            <CardHeader>
              <b>Fast Confirmed Order</b>
            </CardHeader>
            <CardBody>
              {fastConfirmOrder &&
                fastConfirmOrder?.length > 0 &&
                fastConfirmOrder
                  ?.filter(
                    (item) =>
                      item?.tag_no.toLowerCase().includes(selectedTag) ||
                      item?.order_id.toLowerCase().includes(selectedTag) ||
                      item?.customer?.name.toLowerCase().includes(selectedTag) ||
                      item?.customer?.mobile_no.toString().includes(selectedTag)
                  )
                  .map((item) => {
                    return (
                      <Order
                        item={item}
                        reloadData={reloadData}
                        open={open}
                        setOpen={setOpen}
                        selectedOrder={selectedOrder}
                        setSelectedOrder={setSelectedOrder}
                        key={item?._id}
                        from="receiver"
                      />
                    );
                  })}
              {(!fastConfirmOrder || fastConfirmOrder?.length === 0) && (
                <div className="no-data">
                  <img
                    src={NoDataImg}
                    alt="no-data"
                    style={{ height: "50vh" }}
                  />
                  <br />
                  <label style={{ color: "lightgray" }}>No Data Found</label>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </div>

      <Confirmation
        data={showModal}
        confirmModal={submitConfirmed}
        hideModal={hideConfirmationModal}
      />
    </>
  );
};

export default Receiver;
