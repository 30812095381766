import { useSelector } from "react-redux";
import LoaderIcon from "../../assets/icons/loader";
import Colors from "../../constants/colors";
import "./styles.scss";

const Loader = (props) => {
  const showLoading = useSelector((state) => state.ui.showLoading);

  return showLoading ? (
    <div className="loader-main-container">
      <LoaderIcon color={Colors.mediumSlateBlue} width={50} height={50} />
    </div>
  ) : props?.loading === true ? (
    <div className="loader-main-container">
      <LoaderIcon color={Colors.mediumSlateBlue} width={50} height={50} />
    </div>
  ) : null;
};

export default Loader;
