import Text from "../../../components/text";
import ProfileUser from "../../../assets/images/profileUser.png";
import LogoImg from "../../../assets/images/logo.png";
import "../../../constants/styles.scss";
import "./styles.scss";

const Header = ({ userName, onChangeRoutes }) => {
  return (
    <div className="dashboard-header-container">
      <div
        className="dashboard-header-user-profile-container"
        style={{ width: "50%" }}
      >
        <div className="dashboard-header-user-profile-text-container ms-0 cursor-pointer">
          <img
            src={LogoImg}
            alt="ClothiQ"
            width="150px"
            style={{ padding: "0", marginLeft: "50px" }}
            onClick={() => {
              onChangeRoutes("/");
            }}
          />
          {/* {data.map((item) => {
            return (
              <>
                <span className="vertical-line"></span>
                <Text
                  onClick={() => {
                    onChangeRoutes(item?.url, item?.selectedIndex);
                  }}
                  className={`fs-14 fw-b fc-nero menu-box ${
                    selectedBox == item.selectedIndex ? "active-menu-box" : ""
                  }`}
                  value={item?.label}
                />
              </>
            );
          })} */}
          {/* <span className="vertical-line"></span> */}
        </div>
      </div>
      <div className="dashboard-header-user-profile-container">
        <img
          src={ProfileUser}
          alt="profile-img"
          height={25}
          width={25}
          style={{
            background: "white",
            borderRadius: "50%",
            cursor: "pointer",
          }}
          onClick={() => {
            onChangeRoutes("/profile");
          }}
        />
        <div
          className="dashboard-header-user-profile-text-container"
          onClick={() => {
            onChangeRoutes("/profile");
          }}
          style={{ cursor: "pointer" }}
        >
          <Text className="fs-12 fw-b fc-nero" value={<>Hi {userName}</>} />
        </div>
      </div>
    </div>
  );
};

export default Header;
