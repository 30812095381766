import { useState, useEffect } from "react";
import "./styles.scss";
import Loader from "../../components/loader";
import { Col, CardBody, Card, CardHeader } from "reactstrap";
import order from "../../apis/order";
import Badge from "@mui/material/Badge";
import TextField from "@mui/material/TextField";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import NoDataImg from "../../assets/images/no-data.jpg";
import Order from "../order";
import user from "../../apis/user";
import AccessDenied from "../../assets/images/access-denied.jpg";
// import BarcodeReader from "react-barcode-reader";

const Deliver = (props) => {
  const [spinner, setSpinner] = useState(false);
  const [receivedOrder, setReceivedOrder] = useState([]);
  const [readyOrder, setReadyOrder] = useState([]);
  const [rider, setRider] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedTag, setSelectedTag] = useState("");

  const getReceivedOrder = async () => {
    try {
      setSpinner(true);
      let query = `?sortBy=drop_date&sortOrder=asc`;
      const response = await order.getReceivedOrder(query);
      setReceivedOrder(response?.data?.data);
      setSpinner(false);
    } catch (err) {
      setSpinner(false);
    }
  };

  const getReadyOrder = async () => {
    try {
      setSpinner(true);
      let query = `?sortBy=drop_date&sortOrder=asc`;
      const response = await order.getReadyOrder(query);
      setReadyOrder(response?.data?.data);
      setSpinner(false);
    } catch (err) {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getReceivedOrder();
    getReadyOrder();
  }, []);

  const getRiderData = async () => {
    try {
      setSpinner(true);
      const response1 = await user.getAllRider();
      setRider(response1?.data?.data);
      setSpinner(false);
    } catch (err) {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getRiderData();
  }, []);

  const reloadData = () => {
    getReceivedOrder();
    getReadyOrder();
  };

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      if (!e.target.value) return;
      const arr = [...receivedOrder, ...readyOrder];
      const item = arr.find((x) => x.tag_no === e.target.value);
      if (!item) return;
      setSelectedOrder(item);
      setSelectedTag("");
      setOpen(true);
    }
  };

  // const handleScan = (data) => {
  //   console.log(data);
  // };
  // const handleError = (err) => {
  //   console.error(err);
  // };

  if (!props?.is_access) {
    return (
      <>
        <div className="no-access-section">
          <div style={{ height: "25vh" }}></div>
          <img
            src={AccessDenied}
            alt=""
            style={{ width: "20%" }}
          />
          <br />
          <label className="gr">
            You currently does not have access to this page
          </label>
          <br />
          <label className="gr">Please try again latter</label>
        </div>
      </>
    );
  }

  return (
    <>
      {spinner && (
        <div>
          <Loader loading={spinner} />
        </div>
      )}
      {/* <BarcodeReader onError={handleError} onScan={handleScan} /> */}
      <div className="row profile-section">
        <div className="right-head-section">
          <h6 className="page-heading">
            Deliver Details{" "}
            <RotateLeftIcon
              className="reload-data-icon"
              onClick={reloadData}
              style={{ color: "#00ab92" }}
            />
          </h6>
          <div className="transporter-list">
            <TextField
              type="search"
              id="outlined-basic"
              variant="outlined"
              fullWidth
              size="small"
              value={selectedTag}
              label="Search order..."
              style={{ background: "#e2e5ea" }}
              onChange={(e) => {
                setSelectedTag(e.target.value);
              }}
              onKeyDown={keyPress}
            />
          </div>
        </div>
        <Col md={6} className="order-outer-section" style={{ paddingLeft: 0 }}>
          <Card>
            <Badge
              badgeContent={receivedOrder?.length || 0}
              color="secondary"
            ></Badge>
            <CardHeader>
              <b>Waiting For Return Order</b>
            </CardHeader>
            <CardBody>
              {receivedOrder &&
                receivedOrder?.length > 0 &&
                receivedOrder
                  ?.filter(
                    (item) =>
                      item?.tag_no.toLowerCase().includes(selectedTag) ||
                      item?.order_id.toLowerCase().includes(selectedTag) ||
                      item?.customer?.name.toLowerCase().includes(selectedTag) ||
                      item?.customer?.mobile_no.toString().includes(selectedTag)
                  )
                  .map((item) => {
                    return (
                      <Order
                        item={item}
                        reloadData={reloadData}
                        open={open}
                        setOpen={setOpen}
                        selectedOrder={selectedOrder}
                        setSelectedOrder={setSelectedOrder}
                        key={item?._id}
                        rider={rider}
                      />
                    );
                  })}
              {(!receivedOrder || receivedOrder?.length === 0) && (
                <div className="no-data">
                  <img
                    src={NoDataImg}
                    alt="no-data"
                    style={{ height: "50vh" }}
                  />
                  <br />
                  <label style={{ color: "lightgray" }}>No Data Found</label>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
        <Col md={6} className="order-outer-section" style={{ paddingRight: 0 }}>
          <Card>
            <Badge
              badgeContent={readyOrder?.length || 0}
              color="secondary"
            ></Badge>
            <CardHeader>
              <b>Ready For Return Order</b>
            </CardHeader>
            <CardBody>
              {readyOrder &&
                readyOrder?.length > 0 &&
                readyOrder
                  ?.filter(
                    (item) =>
                      item?.tag_no.toLowerCase().includes(selectedTag) ||
                      item?.order_id.toLowerCase().includes(selectedTag) ||
                      item?.customer?.name.toLowerCase().includes(selectedTag) ||
                      item?.customer?.mobile_no.toString().includes(selectedTag)
                  )
                  .map((item) => {
                    return (
                      <Order
                        item={item}
                        reloadData={reloadData}
                        open={open}
                        setOpen={setOpen}
                        selectedOrder={selectedOrder}
                        setSelectedOrder={setSelectedOrder}
                        key={item?._id}
                        rider={rider}
                      />
                    );
                  })}
              {(!readyOrder || readyOrder?.length === 0) && (
                <div className="no-data">
                  <img
                    src={NoDataImg}
                    alt="no-data"
                    style={{ height: "50vh" }}
                  />
                  <br />
                  <label style={{ color: "lightgray" }}>No Data Found</label>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </div>
    </>
  );
};

export default Deliver;
