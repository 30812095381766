import { useState } from "react";
import { useSelector } from "react-redux";
import DashboardRoutes from "../../routes/dashboard";
import SideBar from "./sideBar";
import Header from "./header";
import "./styles.scss";
import "../../constants/styles.scss";
import { useNavigate, useLocation } from "react-router-dom";

const data = [
  {
    label: "Receiver",
    selectedIndex: 1,
    url: "/receiver",
    id: "Receiver"
  },
  {
    label: "Deliver",
    selectedIndex: 2,
    url: "/deliver",
    id: "Deliver"
  },
  {
    label: "Express",
    selectedIndex: 3,
    url: "/express",
    id: "Express"
  },
  {
    label: "Rider",
    selectedIndex: 4,
    url: "/rider",
    id: "Rider"
  },
  {
    label: "Offline Customer",
    selectedIndex: 5,
    url: "/order/-1",
    id: "Offline_customer"
  },
  {
    label: "Detect Order",
    selectedIndex: 6,
    url: "/detect-order",
    id: "Detect_order"
  },
  {
    label: "Location",
    selectedIndex: 7,
    url: "/location",
    id: "Location"
  },
  {
    label: "Orders",
    selectedIndex: 7,
    url: "/order",
    id: "Order"
  },
  {
    label: "Users",
    selectedIndex: 8,
    url: "/user/list",
    id: "Order"
  },
];

const Dashboard = () => {
  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();
  let ind = data.findIndex((x) => x.url === location?.pathname);
  const [selectedBox, setSelectedBox] = useState(ind > -1 ? ind + 1 : 0);

  const onChangeRoutes = (url, ind = 0) => {
    setSelectedBox(ind);
    navigate(url);
  };
  return (
    <div className="container-fluid dashboard-main-container">
      <Header
        userName={authData?.user_data?.name}
        onChangeRoutes={onChangeRoutes}
        selectedBox={selectedBox}
        data={data}
      />
      <div className="dashboard-main-inside-container">
        <SideBar user_data={authData} data={data} />

        <div 
          className="row"
          style={{ margin: "10px", maxHeight: "87vh", overflow: "auto" }}
        >
          <div className="dashboard-right-section">
            <DashboardRoutes user_data={authData?.user_data} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
